<div class="dialog-block">
  <div class="title d-flex align-items-center justify-content-space-between">
    <h4 class="mb-0">Перегляд товару</h4>
    <button class="close-btn bg-transparent p-0 border-none" (click)="close()"><i class="flaticon-close"></i></button>
  </div>
  <div mat-dialog-content>
    <div>
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <div class="img-block">
            <!-- <img [src]="backendUrl + '/uploads/' + data.image" alt=""> -->
            <div class="carousel-block">
              <owl-carousel-o [options]="sliderOptions">
                <ng-container *ngFor="let item of data.images">
                  <ng-template carouselSlide>
                    <img [src]="backendUrl + '/uploads/' + item" alt="card" class="border-radius">
                  </ng-template>
                </ng-container>
              </owl-carousel-o>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <mat-list role="list">
            <mat-list-item role="listitem"><b>Артикул:</b> {{ savedData.article }}</mat-list-item>
            <mat-list-item role="listitem"><b>Ціна:</b> {{ savedData.price }} грн.</mat-list-item>
            <mat-list-item role="listitem"><b>Закупочна ціна:</b> {{ savedData.purchase_price }} грн.</mat-list-item>
            <mat-list-item role="listitem"><b>Постачальник:</b> {{ savedData.provider }}</mat-list-item>
            <mat-list-item role="listitem"><b>Категорія:</b> {{ savedData.category }}</mat-list-item>
          </mat-list>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <form [formGroup]="form">
            <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
              <mat-tab label="Загальне"><br />
                <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
                  <mat-tab label="УКР">
                    <div class="content">
                      <mat-form-field class="width-100">
                        <mat-label>Назва</mat-label>
                        <input formControlName="name_ua" [value]="savedData.name_ua" matInput>
                      </mat-form-field>
                      <div class="width-100" style="padding-bottom: 20px;">
                        <label class="d-block mb-12 fw-semibold gray-color">Опис</label>
                        <angular-editor formControlName="description_ua" [config]="editorConfig"></angular-editor>
                      </div>
                    </div>
                  </mat-tab>
                  <mat-tab label="РУС">
                    <div class="content">
                      <mat-form-field class="width-100">
                        <mat-label>Название</mat-label>
                        <input formControlName="name_ru" [value]="savedData.name_ru" matInput>
                      </mat-form-field>
                      <div class="width-100" style="padding-bottom: 20px;">
                        <label class="d-block mb-12 fw-semibold gray-color">Описание</label>
                        <angular-editor formControlName="description_ru" [config]="editorConfig"></angular-editor>
                      </div>
                    </div>
                  </mat-tab>
                </mat-tab-group>
              </mat-tab>
              <mat-tab label="Атрибути" *ngIf="attributeList.length > 0"><br />
                <div *ngFor="let item of attributeList">
                  <div class="row" style="margin: 0" [formGroupName]="item.id">
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <mat-form-field class="width-100">
                        <mat-label>Атрибут</mat-label>
                        <input matInput [value]="item.name_ua" readonly>
                      </mat-form-field>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <mat-form-field class="width-100">
                          <mat-label>Значення (укр)</mat-label>
                          <input matInput formControlName="name_ua" [value]="form.get(item.id['name_ua'])?.value">
                      </mat-form-field>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <mat-form-field class="width-100">
                          <mat-label>Значення (рус)</mat-label>
                          <input matInput formControlName="name_ru" [value]="form.get(item.id['name_ru'])?.value">
                      </mat-form-field>
                    </div>
                  </div>
                  <hr/>
                </div>
              </mat-tab>
              <mat-tab label="Опції" *ngIf="optionsList.length > 0"><br />
                <cdk-accordion class="option-accordion-list">
                  @for (option of optionsList; track option; let i = $index) {
                  <cdk-accordion-item #accordionItem="cdkAccordionItem" class="option-accordion-item">
                      <div
                          class="option-accordion-item__header"
                          tabindex="0"
                          [attr.id]="'accordion-header-' + i"
                          [attr.aria-expanded]="accordionItem.expanded"
                          [attr.aria-controls]="'accordion-body-' + i">
                          <span>
                              @for (item_option of option.combination_options; track item_option; let index = $index; let last = $last) {
                                      {{ item_option.name_ua }}: {{ item_option.value_ua }}<ng-container *ngIf="!last">, </ng-container>
                              }
                          </span>
                          <span class="option-accordion-item__btn">
                            <button type="button" mat-mini-fab class="tagus" (click)="accordionItem.toggle()">
                                <mat-icon>expand_more</mat-icon>
                            </button>
                        </span>
                      </div>
                      @if(accordionItem.expanded) {
                      <div
                          class="option-accordion-item__body"
                          role="region"
                          [style.display]="accordionItem.expanded ? '' : 'none'"
                          [attr.id]="'accordion-body-' + i"
                          [attr.aria-labelledby]="'accordion-header-' + i"
                      >
                      <br />
                        <div class="row" style="margin: 0">
                          <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
                            <!-- Опція -->
                            <mat-tab label="Опція"><br>
                                <div class="row" style="margin: 0">
                                @for (item_option of option.combination_options; track item_option; let index = $index) {
                                    <div class="option-line">
                                        <div class="option-line__item">
                                            <div class="row" style="margin: 0">
                                                <div class="col-lg-4 col-md-4 col-sm-4">
                                                    <mat-form-field appearance="fill" class="width-100">
                                                        <mat-label>Опція</mat-label>
                                                        <input matInput [value]="item_option.name_ua || null" disabled>
                                                    </mat-form-field>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-sm-4">
                                                    <mat-form-field class="width-100">
                                                        <mat-label>Значення (укр)</mat-label>
                                                        <input matInput  [value]="item_option.value_ua || null" disabled>
                                                    </mat-form-field>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-sm-4">
                                                    <mat-form-field class="width-100">
                                                        <mat-label>Значення (рус)</mat-label>
                                                        <input matInput [value]="item_option.value_ru || null" disabled>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                              </div>
                            </mat-tab>

                            <!-- Основне -->
                            <mat-tab label="Основне"><br>
                              <div class="row" style="margin: 0">
                                  <div class="col-lg-4 col-md-4 col-sm-4">
                                      <mat-form-field class="width-100">
                                          <mat-label>Ціна</mat-label>
                                          <input matInput [value]="option.price || null" disabled>
                                      </mat-form-field>
                                  </div>
                                  <div class="col-lg-4 col-md-4 col-sm-4">
                                      <mat-form-field class="width-100">
                                          <mat-label>Ціна зі знижкою</mat-label>
                                          <input matInput [value]="option.price_new || null" disabled>
                                      </mat-form-field>
                                  </div>
                                  <div class="col-lg-4 col-md-4 col-sm-4">
                                      <mat-form-field class="width-100">
                                          <mat-label>Ціна закупки</mat-label>
                                          <input matInput [value]="option.purchase_price || null" disabled>
                                      </mat-form-field>
                                  </div>
                                  <div class="col-lg-4 col-md-4 col-sm-4">
                                      <mat-form-field class="width-100">
                                          <mat-label>Артикул</mat-label>
                                          <input matInput [value]="option.article" disabled>
                                      </mat-form-field>
                                  </div>
                                  <div class="col-lg-4 col-md-4 col-sm-4">
                                      <mat-form-field appearance="fill" class="width-100">
                                          <mat-label>Наявність</mat-label>
                                          <mat-select [(value)]="option.availability" disabled>
                                              <mat-option [value]="true">Є в наявності</mat-option>
                                              <mat-option [value]="false">Немає в наявності</mat-option>
                                          </mat-select>
                                      </mat-form-field>
                                  </div>
                                  <div class="col-lg-4 col-md-4 col-sm-4">
                                      <mat-form-field appearance="fill" class="width-100">
                                          <mat-label>Статус</mat-label>
                                          <mat-select [value]="option.status" disabled>
                                              <mat-option [value]="true">Активно</mat-option>
                                              <mat-option [value]="false">Не активно</mat-option>
                                          </mat-select>
                                      </mat-form-field>
                                  </div>
                              </div>
                            </mat-tab>


                            <!-- Зображення -->
                            <mat-tab label="Зображення" *ngIf="option.option_gallery.length > 0"><br>
                              <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="checkFoMainImg(option.option_gallery, true)">
                                  <label class="d-block mb-12 fw-semibold gray-color">Зображення</label>
                                  <!-- отображение изображения -->
                                    <div class="image-block border-radius">
                                        <img [src]="utilityService.addSuffixToImageUrl(backendUrl + '/uploads/' + checkFoMainImg(option.option_gallery, true).image, '60')">
                                    </div>
                              </div>
          
                              <div class="col-lg-12 col-md-12 col-sm-12" style="margin-top: 20px;" *ngIf="checkFoMainImg(option.option_gallery, false)">
                                  <label class="d-block mb-12 fw-semibold gray-color">Додаткові зображення</label>
                                  <!-- отображение изображений -->
                                  <ng-container *ngIf="option.option_gallery">
                                      <div class="image-block__list">
                                          <ng-container *ngFor="let image of option.option_gallery">
                                            <ng-container *ngIf="!image.is_main">
                                              <div class="image-block border-radius">
                                                <img [src]="utilityService.addSuffixToImageUrl(backendUrl + '/uploads/' + image.image, '60')">
                                              </div>
                                            </ng-container>
                                          </ng-container>
                                      </div>
                                  </ng-container>
                              </div>
                            </mat-tab>
                          </mat-tab-group>
                        </div>
                      </div>
                      }
                  </cdk-accordion-item>
                  }
              </cdk-accordion>
              </mat-tab>
            </mat-tab-group>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="buttons-action">
    <button type="button" class="gray" (click)="close()"><i class="ri-close-fill"></i> Закрити</button>
    <button type="button" (click)="saveItem()" [disabled]="isDisabledSaveBtn"><i class="ri-add-fill"></i> Зберегти</button>
  </div>
</div>