<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Імпорт. Налаштування</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Дашборд
            </a>
        </li>
        <li class="breadcrumb-item gray-color">Імпорт</li>
        <li class="breadcrumb-item fw-semibold">Налаштування</li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card">
    <mat-card-header>
        <h5 class="mb-0">Список атрибутів</h5>
    </mat-card-header>
    <app-local-success></app-local-success>
    <mat-card-content>
        <div class="d-flex">
            <mat-form-field appearance="fill" class="width-100">
                <mat-label>Пошук по назвi категорії</mat-label>
                <input matInput
                        #searchBlock
                        (keyup)="applyFilter($event)" placeholder="Введіть назву категорії">
            </mat-form-field>
            <div style="margin-left: 16px">
                <button mat-icon-button (click)="onClearSearch()">
                    <i class="ri-delete-bin-2-line"></i>
                </button>
            </div>
        </div>
        <div class="mat-elevation-z8 tagus-mat-table">
            <table mat-table [dataSource]="dataSource">

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef class="text-start">Назва категорії</th>
                    <td mat-cell *matCellDef="let element" class="text-start">
                        <div class="d-flex align-items-center">
                            <span class="d-flex align-items-center product-info">
                                {{element.name_ua}}
                            </span>
                        </div>
                    </td>
                </ng-container>

                <!-- Filter Column -->
                <ng-container matColumnDef="filters">
                    <th mat-header-cell *matHeaderCellDef class="text-start">Атрибути</th>
                    <td mat-cell *matCellDef="let element" class="text-start">
                        <app-chips-list-autocomplete
                            [categoryId]="element._id"
                            [selectedFilters]="element.attributes"
                            [filtersList]="attributesList">
                        </app-chips-list-autocomplete>
                    </td>
                </ng-container>

                <!-- Action Column -->
                <ng-container matColumnDef="action">
                    <th style="display: flex;justify-content: flex-end" mat-header-cell *matHeaderCellDef>Дія</th>
                    <td style="display: flex;justify-content: flex-end" mat-cell *matCellDef="let element">
                        <button mat-icon-button (click)="save(element._id)">
                            <mat-icon>save</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator 
                [length]="totalRecords"
                [pageSize]="pageSize"
                (page)="onPageChange($event)"
                [pageSizeOptions]="pageSizeOptions"
                showFirstLastButtons>
            </mat-paginator>
        </div>
    </mat-card-content>
</mat-card>