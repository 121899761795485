import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorService } from 'src/app/components/common/notifications/errors/error.service';
import { SuccessService } from 'src/app/components/common/notifications/success/success.service';
import { DEFAULT_MESSAGES } from 'src/app/constants/default-messages';
import { EndpointTypeEnum } from 'src/app/enums/endpoint-type.enum';
import { ErrorTypeEnum } from 'src/app/enums/error-type.enum';
import { ApiService } from 'src/app/services/api.service';
import { HomeCategoriesCreateComponent } from '../home-categories-create/home-categories-create.component';

@Component({
    selector: 'app-home-categories-edit',
    templateUrl: './home-categories-edit.component.html',
    styleUrl: '../home-categories-create/home-categories-create.component.scss'
})
export class HomeCategoriesEditComponent extends HomeCategoriesCreateComponent implements OnInit {

    categoryId: any = this.route.snapshot.paramMap.get('id');
    categoryData: any;

    constructor(
        private route: ActivatedRoute,
        protected override formBuilder: FormBuilder,
        protected override apiService: ApiService,
        protected override errorService: ErrorService,
        protected override successService: SuccessService,
        protected override router: Router
    ) {  
        super(formBuilder, apiService, errorService, successService, router)
    }

    ngOnInit() {
        if (this.categoryId !== null) {
            this.getDataByCategoryID(this.categoryId);
        }
    }


    getDataByCategoryID(categoryID: string) {
        this.apiService
            .get(EndpointTypeEnum.Main, '/ads/categories-home/' + categoryID, true)
            .subscribe({
                next: (response: any) => {
                    this.categoryData = response.data;

                    this.form.patchValue({
                        header_ua: this.categoryData.header_ua,
                        header_ru: this.categoryData.header_ru,
                        sort: this.categoryData.sort,
                        link_show_all_ua: this.categoryData?.link_show_all_ua,
                        link_show_all_ru: this.categoryData?.link_show_all_ru,
                    })

                    this.addedBannerListUA = this.categoryData.banners_pc_ua;
                    this.addedBannerListRU = this.categoryData.banners_pc_ru;
                    this.addedBannerListMob = this.categoryData.banners_mob;
                    this.selectedOptions = this.categoryData.options;
                },
                error: (err) => {
                    this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
                },
            })
    }


    // отправка формы
    override onFormSubmit(form: FormGroup): void {
        this.formWasSubmitted = true;

        // проверка на дубликаты в позициях баннеров
        if (this.checkBannerPosition()) {
            this.errorService.addError(ErrorTypeEnum.Local, ['Позиції повинні відрізнятись. Перевірте уважно форму']);
            return;
        } else {
            this.errorService.clearErrors(ErrorTypeEnum.Local);
        }

        // если есть ошибки валидации в полях формы, не даем отправить форму
        if (form.invalid) {
            this.errorService.addError(ErrorTypeEnum.Local, [DEFAULT_MESSAGES.FORM_ERROR]);
            return;
        }

        // получаем значения из формы
        const formValue = form.value;
        formValue.sort = Number(formValue.sort)
        formValue.options = this.selectedOptions.map(item => item._id);
        formValue.banners_pc_ua = this.addedBannerListUA;
        formValue.banners_pc_ru = this.addedBannerListRU;
        formValue.banners_mob = this.addedBannerListMob;

        // отправка формы
        this.apiService
            .put(EndpointTypeEnum.Main, '/ads/categories-home/' + this.categoryId, true, formValue)
            .subscribe({
                next: (response: any) => {
                    this.router.navigate(['/main', 'advertisement', 'categories-home']);
                    this.successService.add(ErrorTypeEnum.Local, [DEFAULT_MESSAGES.SUCCESS]);
                },
                error: (err: any) => {
                    this.errorService.addError(ErrorTypeEnum.Local, [err.error.message]);
                },
            });
    }
}
