import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { debounceTime } from 'rxjs';
import { ToolbarEditor2ConfigService } from 'src/app/configs/toolbar-editor-2.config';
import { EndpointTypeEnum } from 'src/app/enums/endpoint-type.enum';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-detail',
    templateUrl: './detail.component.html',
    styleUrl: './detail.component.scss'
})
export class DetailComponent implements OnInit {
    data = inject(MAT_DIALOG_DATA);
    attributeList: any[];
    backendUrl: string;
    savedData: any;
    isDisabledSaveBtn: boolean = true;

    // форма
    form!: FormGroup;

    // редактор
    editorConfig: AngularEditorConfig;

    // slider
    sliderOptions: OwlOptions = {
        items: 1,
		nav: true,
		loop: true,
		margin: 25,
		dots: false,
		autoplay: false,
		smartSpeed: 1000,
		autoplayHoverPause: true,
        navText: [
			"<i class='flaticon-chevron-1'></i>",
			"<i class='flaticon-chevron'></i>"
		]
    }

    constructor(
        public dialogRef: MatDialogRef<DetailComponent>,
        private formBuilder: FormBuilder,
        private toolbarEditor2ConfigService: ToolbarEditor2ConfigService,
        private apiService: ApiService,
    ) {
        this.editorConfig = this.toolbarEditor2ConfigService.getConfig();

        this.attributeList = [];
        this.backendUrl = environment.backendUrl;
        this.savedData = JSON.parse(JSON.stringify(this.data))

        this.initForm();
        this.patchValues();

        this.form.valueChanges
        .pipe(debounceTime(100))
        .subscribe(res => {
            this.saveEditableData(res);
        })
    }

    ngOnInit() {
        this.combineAttribute();
    }

    initForm(): void {
        // инициализируем поля из формы и валидируем
        this.form = this.formBuilder.group({
            // ru
            name_ru: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
            description_ru: [''],
            // ua
            name_ua: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
            description_ua: [''],
        });
    }

    patchValues() {
        // установка значения полей
        this.form.patchValue({
            name_ru: this.savedData.name_ru,
            name_ua: this.savedData.name_ua,
            description_ru: this.savedData.description_ru,
            description_ua: this.savedData.description_ua,
        });
    }

    saveEditableData(data: any) {
        const updateSavedData = (source: any, target: any) => {
            Object.keys(source).forEach(key => {
                if (typeof source[key] === 'object' && source[key] !== null) {
                    target[key] = target[key] || {};
                    updateSavedData(source[key], target[key]);
                } else if (target[key] !== source[key]) {
                    target[key] = source[key];
                }
            });
        };

        updateSavedData(data, this.savedData);
        
        this.isDisabledSaveBtn = this.getBtnState();
    }

    getBtnState() {
        const isEqual: any = (data: any, saved: any) => {
            if (typeof data !== 'object' || data === null || typeof saved !== 'object' || saved === null) {
                return data === saved;
            }
    
            const dataKeys = Object.keys(data);
            const savedKeys = Object.keys(saved);
    
            if (dataKeys.length !== savedKeys.length) {
                return false;
            }
    
            return dataKeys.every(key => isEqual(data[key], saved[key]));
        };
    
        return isEqual(this.data, this.savedData);
    }

    close() {
        this.dialogRef.close({action: 'close'});
    }

    combineAttribute() {
        const attr = Object.keys(this.savedData).filter(item => item.includes('Характеристика'));
        this.attributeList = attr.map((item: any) => {
            return {
                name_ua: this.parsingAttributeName(item),
                value: this.savedData[item],
                id: item
            }
        });
        this.addToForm();
    }

    addToForm() {
        this.attributeList.forEach(item => {
            let formObj = Object.fromEntries(
                Object.keys(item.value).map(key => [
                    key,
                    new FormControl(item.value[key], [
                        Validators.required,
                        Validators.minLength(2),
                        Validators.maxLength(255)
                    ])
                ])
            );
            this.form.addControl(item.id, new FormGroup(formObj))
        })
    }

    parsingAttributeName(item: string) {
        const string = item.split('/ ');
        return string[1];
    }

    saveItem() {
        this.data = this.savedData;
        this.updateItem();
    }

    updateItem() {
        this.apiService.post(EndpointTypeEnum.Main, '/import-by-categories/step-3', true, [this.savedData])
            .subscribe({
                next: (response) => {
                    this.dialogRef.close({action: 'save', data: this.data});
                }
            })
    }
}
