import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ErrorService } from 'src/app/components/common/notifications/errors/error.service';
import { EndpointTypeEnum } from 'src/app/enums/endpoint-type.enum';
import { ErrorTypeEnum } from 'src/app/enums/error-type.enum';
import { ApiService } from 'src/app/services/api.service';
import { DetailComponent } from './detail/detail.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { SuccessService } from 'src/app/components/common/notifications/success/success.service';
import { environment } from 'src/environments/environment';
import { UtilityService } from 'src/app/services/utility.service';
import { Subject, takeUntil } from 'rxjs';

interface IDownloadRequest {
    category: string;
    main_options?: string[];
}

@Component({
    selector: 'app-import-products',
    templateUrl: './import-products.component.html',
    styleUrl: './import-products.component.scss'
})
export class ImportProductsComponent implements OnDestroy, AfterViewInit {

    private destroy$ = new Subject<void>();

    attributesForDropdown: any;
    categoryID: string;
    selectedIndex: number = 0;
    fileName: string = '';
    optionsList: any[] = [];
    selectedOptions: any[] = []

    isFileAlreadyUpload: boolean = false;
    countOfNewProduct: number;
    countOfExistProduct: number;
    countOfAdded: number;
    countOfUpdated: number;
    countOfDeleted: number;
    backendUrl: string;
    disableUploadBtn: boolean = false;
    isSuccessImport: boolean = false;
    isLoader: boolean = false;
    isDisabledImportBtn: boolean = false;

    // таблица
    displayedColumns: string[] = ['photo', 'article', 'name', 'price', 'price_opt', 'action'];
    ELEMENT_DATA: any[] = [];
    dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);

    // пагинация
    totalRecords = 0;
    timeout: any = null;
    pageSizeOptions: any[] = [10];
    pageIndex: number = 0;
    pageSize: number = this.pageSizeOptions[0];

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatTable,{static:true}) table: MatTable<any>;
    @ViewChild('fileUpload') fileInput: ElementRef;

    constructor(
        private apiService: ApiService,
        private errorService: ErrorService,
        private successService: SuccessService,
        private utilityService: UtilityService,
        public dialog: MatDialog
    ) {
        this.backendUrl = environment.backendUrl;
        this.getAllCategories();
        this.getAllOptions();
    }


    ngAfterViewInit() {
        this.paginator.pageIndex = 0;
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();

        this.successService.clear(ErrorTypeEnum.Global);
        this.successService.clear(ErrorTypeEnum.Local);
        this.errorService.clearErrors(ErrorTypeEnum.Global);
        this.errorService.clearErrors(ErrorTypeEnum.Local);
    }

    // получаем список категорий
    getAllCategories(): void {
        this.apiService
            .get(EndpointTypeEnum.Main, '/import-by-categories/step-1/categories', true)
            .subscribe({
                next: (response: any) => {
                    this.attributesForDropdown = response.data;
                },
                error: (err) => {
                    this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
                },
            })
    }

    // Получаем список опций
    getAllOptions(): void {
        this.apiService
            .get(EndpointTypeEnum.Main, '/import-by-categories/step-1/options', true)
            .subscribe({
                next: (response: any) => {
                    this.optionsList = response.data;
                },
                error: (err) => {
                    this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
                }
            })
    }

    // записываем Ид категории
    setCategory(event: any) {
        this.categoryID = event.value;
    }

    downloadTemplate() {
        const optionIds = this.selectedOptions.map(item => item._id);
        const request: IDownloadRequest = {
            category: this.categoryID,
        }
        if (optionIds.length > 0) request.main_options = optionIds;

        const category = this.attributesForDropdown.find((item: any) => item._id === this.categoryID);
        const name = this.utilityService.convertToUrl(category.name_ua);
        this.apiService
            .post(EndpointTypeEnum.Main, '/import-by-categories/step-1/download-excel', true, request, true)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response: any) => {
                    const a = document.createElement('a');
                    const objectUrl = URL.createObjectURL(response);
                    a.href = objectUrl;
                    a.download = name + '.xlsx'; // имя файла, которое будет предложено для скачивания
                    a.click();
                },
                error: (err) => {
                    this.errorService.addError(ErrorTypeEnum.Local, [err.error.message]);
                },
            })
    }

    nextStep() {
        if (this.selectedIndex != 4) {
            this.selectedIndex = this.selectedIndex + 1;
        }
    }

    previousStep() {
        if (this.selectedIndex != 0) {
            this.selectedIndex = this.selectedIndex - 1;
        }
    }

    onFileSelected(event: any) {
        const file: File = event.target.files[0];
        if (file) {
            this.fileName = file.name;
            this.isFileAlreadyUpload = false;
            this.disableUploadBtn = false;
            this.errorService.clearErrors(ErrorTypeEnum.Local);
        }
    }

    uploadFile() {
        this.disableUploadBtn = true;
        this.isLoader = true;
        const formData = new FormData();
        formData.append("file", this.fileInput.nativeElement.files[0]);

        this.apiService
            .postMultipartFormData(EndpointTypeEnum.Main, '/import-by-categories/step-2/upload-excel', true, formData, false)
            .subscribe({
                next: (response: any) => {
                    this.isFileAlreadyUpload = true;
                    this.isLoader = false;
                    this.getDataImport();
                },
                error: (err) => {
                    this.errorService.addError(ErrorTypeEnum.Local, [err.error.message]);
                    this.fileInput.nativeElement.value = '';
                    this.isLoader = false;
                    this.isFileAlreadyUpload = false;
                },
            })
    }

    getDataImport() {
        this.apiService.get(EndpointTypeEnum.Main, '/import-by-categories/step-3', true, null, {
            'page': this.pageIndex
        })
            .subscribe({
                next: (response: any) => {
                    if (response.data) {
                        this.totalRecords = response.data.total_items;
                        this.paginator.pageIndex = this.pageIndex;
                        this.countOfNewProduct = response.data.new_articles;
                        this.countOfExistProduct = response.data.existing_articles;
                        this.dataSource.data = response.data.excel;
                    }
                },
                error: (err) => {
                    this.errorService.addError(ErrorTypeEnum.Local, [err.error.message]);
                },
            })
    }

    getDeletedItems() {
        this.apiService.get(EndpointTypeEnum.Main, '/import-by-categories/step-3', true, null, {
            'page': this.pageIndex
        })
            .subscribe({
                next: (response: any) => {
                    if (response.data) {
                        this.countOfDeleted = response.data.deleted_items;
                        this.isDisabledImportBtn = this.totalRecords > this.countOfDeleted ? false : true;
                    }
                },
                error: (err) => {
                    this.errorService.addError(ErrorTypeEnum.Local, [err.error.message]);
                },
            })
    }

    openDialog(el: any) {
        this.dialog.open(DetailComponent, {
            data: el
        })
        .afterClosed().subscribe(result => {
            if (result?.action === 'save') this.getDataImport();
        });
    }

    onPageChange(event: PageEvent): void {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;

        this.getDataImport();
    }

    startImport() {
        this.isLoader = true;
        this.isDisabledImportBtn = true;
        this.apiService.post(EndpointTypeEnum.Main, '/import-by-categories/step-4', true, {})
            .subscribe({
                next: (response) => {
                    this.isSuccessImport = true;
                    this.countOfAdded = response.data.added;
                    this.countOfUpdated = response.data.updated;
                    this.isLoader = false;
                }
            })
    }

    newImport() {
        this.selectedIndex = 0;
        this.isFileAlreadyUpload = false;
        this.isDisabledImportBtn = false;
        this.isSuccessImport = false;
        this.fileInput.nativeElement.value = '';
        this.fileName = '';
    }

    updateItem(element: any) {
        element.is_deleted = !element.is_deleted;
        this.apiService.post(EndpointTypeEnum.Main, '/import-by-categories/step-3', true, [element])
            .subscribe({
                next: (response) => {
                    this.getDeletedItems();
                }
            })
    }
}
