import { Directive, ElementRef, Input, Output } from "@angular/core";
import { debounce, distinctUntilChanged, fromEvent, map, timer } from "rxjs";

@Directive({
  selector: 'input[debounceInput]'
})
export class DebounceInputDirective {
  @Input()
  debounceTime: number = 0;

  constructor(private el: ElementRef<HTMLInputElement>) {}

  @Output()
  readonly debounceInput = fromEvent(this.el.nativeElement, 'input').pipe(
    debounce(() => timer(this.debounceTime)),
    map(() => this.el.nativeElement.value),
    distinctUntilChanged()
  );
}