<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Замовлення</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Дашборд
            </a>
        </li>
        <li class="breadcrumb-item gray-color">Каталог</li>
        <li class="breadcrumb-item gray-color">Замовлення</li>
        <li class="breadcrumb-item fw-semibold">Редагування замовлення</li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card">
<!--    <mat-card-header>-->
<!--        <h5 class="mb-0">Редагування замовлення</h5>-->
<!--        <div class="d-flex align-items-center">-->
<!--            <button mat-raised-button class="tagus small margin-right-10px" form="form" type="submit">Зберегти</button>-->
<!--            <a class="small" mat-stroked-button routerLink="/main/sales/payment-methods" color="warn">Вийти</a>-->
<!--        </div>-->
<!--    </mat-card-header>-->
    <mat-card-content>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <mat-card class="mb-25 tagus-card">
                    <mat-card-header class="border-bottom">
                        <h5 class="mb-0">Клієнт (замовник)</h5>
                    </mat-card-header>
                    <mat-card-content class="box">
                        <h5 class="fw-semibold mb-15">
                            {{ order?.client_firstname }}
                            {{ order?.client_lastname }}
                        </h5>
                        <ul class="list ps-0 mb-0 mt-0">
                            <li class="gray-color position-relative">
                                <span class="fw-medium d-inline-block">Телефон:</span>
                                {{ order?.client_phone }}
                            </li>
                            <li class="gray-color position-relative">
                                <span class="fw-medium d-inline-block">Email:</span>
                                {{ order?.client_email }}
                            </li>
                        </ul>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-lg-4 col-md-6">
                <mat-card class="mb-25 tagus-card">
                    <mat-card-header class="border-bottom">
                        <h5 class="mb-0">Отримувач</h5>
                    </mat-card-header>
                    <mat-card-content class="box">
                        <h5 class="fw-semibold mb-15">
                            <ng-container *ngIf="order && !order.recipient_firstname && !order.recipient_lastname">
                                {{ order?.client_firstname }}
                                {{ order?.client_lastname }}
                            </ng-container>
                            <ng-container *ngIf="order && order.recipient_firstname && order.recipient_lastname">
                                {{ order?.recipient_firstname }}
                                {{ order?.recipient_lastname }}
                            </ng-container>
                        </h5>
                        <ul class="list ps-0 mb-0 mt-0">
                            <li class="gray-color position-relative">
                                <span class="fw-medium d-inline-block">Телефон:</span>
                                <ng-container *ngIf="order && !order.recipient_phone">
                                    {{ order?.client_phone }}
                                </ng-container>
                                <ng-container *ngIf="order && order.recipient_phone">
                                    {{ order?.recipient_phone }}
                                </ng-container>
                            </li>
                            <li class="gray-color position-relative">
                                <span class="fw-medium d-inline-block">Email:</span>
                                <ng-container *ngIf="order && !order.recipient_email">
                                    {{ order?.client_email }}
                                </ng-container>
                                <ng-container *ngIf="order && order.recipient_email">
                                    {{ order?.recipient_email }}
                                </ng-container>
                            </li>
                            <li class="gray-color position-relative">
                                <span class="fw-medium d-inline-block">Доставка:</span>
                                <ng-container *ngIf="order && order.type_delivery === 'office'">
                                    До вiддiлення
                                </ng-container>
                                <ng-container *ngIf="order && order.type_delivery === 'courier'">
                                    Кур'єром
                                </ng-container>
                                <ng-container *ngIf="order && order.type_delivery === 'parcel_machine'">
                                    Поштомат
                                </ng-container>
                                <ng-container *ngIf="order && order.type_delivery === 'pickup'">
                                    Самовивiз
                                </ng-container>
                            </li>
                            <li class="gray-color position-relative">
                                <span class="fw-medium d-inline-block">Адреса:</span>
                                {{ order?.address }}<ng-container *ngIf="deliveryInfo?.Flat"> кв. {{ deliveryInfo.Flat }}</ng-container>, {{ order?.city }}
                                <ng-container *ngIf="deliveryInfo?.Floor"><br />Поверх: {{ deliveryInfo?.Floor }}</ng-container>
                                <ng-container *ngIf="deliveryInfo?.Doorphone"><br />Домофон: {{ deliveryInfo?.Doorphone }}</ng-container>
                            </li>
                        </ul>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-lg-4 col-md-6">
                <mat-card class="mb-25 tagus-card">
                    <mat-card-header class="border-bottom">
                        <h5 class="mb-0">Оплата</h5>
                    </mat-card-header>
                    <mat-card-content class="box">
                        <ul class="list ps-0 mb-0 mt-0">
                            <li class="gray-color position-relative">
                                <span class="fw-medium d-inline-block">Метод оплати:</span>
                                {{ order?.payment_method.name_ua }}
                            </li>
<!--                            <li class="gray-color position-relative" *ngIf="order?.payment_method.is_paid">-->
<!--                                <span class="fw-medium d-inline-block">Успiшна оплата:</span>-->
<!--                                <ng-container *ngIf="order?.successful_payment">-->
<!--                                    ✅ Так-->
<!--                                </ng-container>-->
<!--                                <ng-container *ngIf="!order?.successful_payment">-->
<!--                                    ❌ Нi-->
<!--                                </ng-container>-->
<!--                            </li>-->
                            <li class="gray-color position-relative">
                                <span class="fw-medium d-inline-block">Пояснення:</span>
                                {{ order?.explanation }}
                            </li>
                        </ul>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>

    </mat-card-content>

    <mat-card-content>
        <div class="row justify-content-center">
            <div class="col-lg-12 col-xl-12 col-md-12">
                <mat-card class="mb-25 tagus-card">
                    <mat-card-header>
                        <h5 class="mb-0">Замовлення #{{ order?.order_number }}</h5>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="table-responsive">
                            <table class="table" style="width:100%">
                                <thead>
                                <tr>
                                    <th class="fw-semibold gray-color text-start">Товар</th>
                                    <th class="fw-semibold gray-color">Артикул</th>
                                    <th class="fw-semibold gray-color">Кiлькiсть</th>
                                    <th class="fw-semibold gray-color">Цiна за одиницю</th>
                                    <th class="fw-semibold gray-color">Загалом</th>
<!--                                    <th class="fw-semibold gray-color text-end">Total Amount</th>-->
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let order_option of order?.options">
                                    <ng-container *ngIf="order_option.option">
                                        <td class="text-start">
                                            <a [routerLink]="['/market', order_option.option.product.market, 'products', 'edit', order_option.option.product._id]" class="d-flex align-items-center product-info">
                                                <ng-container *ngFor="let image of order_option.option.product.gallery">
                                                    <img *ngIf="image.is_main" [src]="backendUrl + '/uploads/' + image.image" class="border-radius me-12" width="50" alt="product">
                                                </ng-container>
                                                <div class="title">
                                                    <h5 class="fw-semibold mb-5">{{ order_option.option.product.name_ua }}</h5>
                                                    <span class="d-block muted-color fw-medium" *ngIf="order_option.option.type === 'custom'">
                                                        Опцiя: <span class="gray-color">{{ order_option.option.main_option.name_ua }} {{ order_option.option.value_ua }}</span>
                                                    </span>
                                                </div>
                                            </a>
                                        </td>
                                        <td class="gray-color">{{ order_option.option.product.article }}</td>
                                        <td class="gray-color">{{ order_option.qty }}</td>
                                        <td class="gray-color">
                                            <ng-container *ngIf="order_option.option.price_new">
                                                <s>{{ order_option.option.price }} ₴</s> {{ order_option.option.price_new }} ₴
                                            </ng-container>
                                            <ng-container *ngIf="!order_option.option.price_new">
                                                {{ order_option.option.price }} ₴
                                            </ng-container>
                                        </td>
                                        <td class="gray-color">
                                            <ng-container *ngIf="order_option.option.price_new">
                                                {{ order_option.option.price_new * order_option.qty }} ₴
                                            </ng-container>
                                            <ng-container *ngIf="!order_option.option.price_new">
                                                {{ order_option.option.price * order_option.qty }} ₴
                                            </ng-container>
                                        </td>
                                    </ng-container>
                                </tr>

                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td class="fw-semibold gray-color">Попередня сума:</td>
                                    <td class="fw-semibold text-end">{{ totalSumWithoutSales | number:'1.2-2' }} ₴</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td class="fw-semibold gray-color">Знижка:</td>
                                    <td class="fw-semibold text-end">{{ totalSumSales | number:'1.2-2' }} ₴</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td class="fw-semibold gray-color">Всього:</td>
                                    <td class="fw-semibold text-end">{{ order?.total_sum | number:'1.2-2' }} ₴</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>

        <h5 class="mb-0">Коментар клієнта</h5>
        {{ order?.comment }}

        <h5 class="mb-0" style="margin-top: 20px;">Статус замовлення</h5>
        <app-local-error></app-local-error>
        <app-local-success></app-local-success>
        <form [formGroup]="form" (submit)="onFormSubmit(form)" id="form">
            <mat-form-field appearance="fill" class="width-100">
                <mat-label>Статус</mat-label>
                <mat-select formControlName="status">
                    <mat-option [value]="'in_process'">В обробцi</mat-option>
                    <mat-option [value]="'not_payed'">Не сплачений</mat-option>
                    <mat-option [value]="'refund'">Повернення</mat-option>
                    <mat-option [value]="'delivered'">Доставлено</mat-option>
                    <mat-option [value]="'failed'">Невдалий</mat-option>
                    <mat-option [value]="'canceled'">Скасовано</mat-option>
                    <mat-option [value]="'sent'">Відправлено</mat-option>
                    <mat-option [value]="'accepted'">Прийнятий</mat-option>
                    <mat-option [value]="'completed'">Завершений</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="width-100">
                <mat-label>Коментар</mat-label>
                <textarea formControlName="comment_admin" rows="5" matInput></textarea>
            </mat-form-field>
        </form>

        <button mat-raised-button class="tagus small margin-right-10px" form="form" type="submit">Зберегти</button>

    </mat-card-content>
</mat-card>
