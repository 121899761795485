import {Component, OnInit} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {ApiService} from "../../../services/api.service";
import {UtilityService} from "../../../services/utility.service";
import {ErrorService} from "../../common/notifications/errors/error.service";
import {SuccessService} from "../../common/notifications/success/success.service";
import {EndpointTypeEnum} from "../../../enums/endpoint-type.enum";
import {ErrorTypeEnum} from "../../../enums/error-type.enum";
import {DEFAULT_MESSAGES} from "../../../constants/default-messages";

@Component({
  selector: 'app-banners-above-categories',
  templateUrl: './banners-above-categories.component.html',
  styleUrl: './banners-above-categories.component.scss'
})
export class BannersAboveCategoriesComponent implements OnInit {
    backendUrl: string = environment.backendUrl;
    banners: any;
    form!: FormGroup;
    formWasSubmitted = false;
    addedCategories: any[] = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private apiService: ApiService,
        private formBuilder: FormBuilder,
        private utilityService: UtilityService,
        private errorService: ErrorService,
        private successService: SuccessService,
    ) {
        this.initForm();
    }

    // инициализация формы
    initForm(): void {
        // инициализируем поля из формы и валидируем
        this.form = this.formBuilder.group({
            added_categories: [''],
        });
    }

    ngOnInit() {
        this.getCategories();
    }

    addCategory() {
        this.addedCategories.push({
            image_mob_ru: '',
            image_mob_ua: '',
            link_ua: '',
            link_ru: '',
            category: '',
        });
    }

    removeCategory(index: number) {
        this.addedCategories.splice(index, 1);
    }

    onUpdateCategory(index: number, field: string, event: any): void {
        switch (field) {
            case 'link_ua':
                // записываем значение поля
                this.addedCategories[index].link_ua = event.target.value;
                break;
            case 'link_ru':
                // записываем значение поля
                this.addedCategories[index].link_ru = event.target.value;
                break;
            case 'category':
                // записываем значение поля
                this.addedCategories[index].category = event.value;
                break;
            default:
                break;
        }
    }

    uploadImage(index: number, event: any, type: 'image_mob_ua' | 'image_mob_ru'): void {
        const filesList: FileList = event.target.files;
        const files = Array.from(filesList);
        const formData = new FormData();

        formData.append('image', files[0]);
        this.apiService
            .postMultipartFormData(EndpointTypeEnum.Requests, '/upload-files', true, formData, true)
            .subscribe({
                next: (response: any) => {
                    if (type === 'image_mob_ua') {
                        this.addedCategories[index].image_mob_ua = response.data;
                    } else if (type === 'image_mob_ru') {
                        this.addedCategories[index].image_mob_ru = response.data;
                    }
                    this.errorService.clearErrors(ErrorTypeEnum.Local);
                },
                error: (err: any) => {
                    this.errorService.addError(ErrorTypeEnum.Local, [err.error.message]);
                },
            });
    }

    // получение данных для формы
    getCategories() {
        this.apiService
            .get(EndpointTypeEnum.Main, '/banners-above-categories', true)
            .subscribe({
                next: (response: any) => {
                    this.banners = response.data;
                    for (const banner of this.banners) {
                        this.addedCategories.push({
                            image_mob_ru: banner.image_mob_ru,
                            image_mob_ua: banner.image_mob_ua,
                            link_ua: banner.link_ua,
                            link_ru: banner.link_ru,
                            category: banner.category,
                        });
                    }
                },
                error: (err) => {
                    this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
                },
            });
    }

    // отправка формы
    onFormSubmit(form: FormGroup): void {
        this.formWasSubmitted = true;
        // если есть ошибки валидации в полях формы, не даем отправить форму
        if (form.invalid) {
            this.errorService.addError(ErrorTypeEnum.Local, [DEFAULT_MESSAGES.FORM_ERROR]);
            return;
        }

        // получаем значения из формы
        const formValue = form.value;
        formValue.added_categories = this.addedCategories;

        // отправка формы
        this.apiService
            .put(EndpointTypeEnum.Main, '/banners-above-categories', true, form.value)
            .subscribe({
                next: (response: any) => {
                    this.successService.add(ErrorTypeEnum.Local, [DEFAULT_MESSAGES.SUCCESS]);
                    this.addedCategories = [];
                    // form.controls['opengraph_image'].reset();
                    this.getCategories();
                },
                error: (err: any) => {
                    this.errorService.addError(ErrorTypeEnum.Local, [err.error.message]);
                },
            });
    }
}
