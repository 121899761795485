<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Категорії на головній</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Дашборд
            </a>
        </li>
        <li class="breadcrumb-item gray-color">Реклама</li>
        <li class="breadcrumb-item fw-semibold">Категорії на головній</li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card">
    <mat-card-header>
        <h5 class="mb-0">Категорії</h5>
        <div class="d-flex align-items-center">
            <ng-container *ngIf="listForDelete.length !== 0"><button class="small margin-right-10px" mat-stroked-button color="warn" (click)="deleteSelected()">Видалити обрані ({{ listForDelete.length }})</button></ng-container>
            <button mat-raised-button class="tagus small" [routerLink]="['/main/advertisement/categories-home/create']">Створити</button>
        </div>
    </mat-card-header>
    <app-local-success></app-local-success>
    <mat-card-content>
        <div class="mat-elevation-z8 tagus-mat-table">
            <table mat-table [dataSource]="dataSource">

                <!-- Header Column -->
                <ng-container matColumnDef="header">
                    <th mat-header-cell *matHeaderCellDef class="text-start">Заголовок</th>
                    <td style="width: 40%" mat-cell *matCellDef="let element" class="text-start">
                        <div class="d-flex align-items-center">
                            <mat-checkbox 
                                [checked]="element.is_deleted"
                                (change)="updateItem(element)">
                            </mat-checkbox>
                            <span class="d-flex align-items-center product-info" [routerLink]="['/main', 'advertisement', 'categories-home', 'edit', element._id]">
                                {{element.header_ua}}
                            </span>
                        </div>
                    </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef class="text-start">Назва категорії</th>
                    <td mat-cell *matCellDef="let element" class="text-start">
                        {{ element.name_ua }}
                    </td>
                </ng-container>

                <!-- Sort Column -->
                <ng-container matColumnDef="sort">
                    <th mat-header-cell *matHeaderCellDef class="text-start">Сортування</th>
                    <td mat-cell *matCellDef="let element" class="text-start">
                        {{ element.sort }}
                    </td>
                </ng-container>

                <!-- Action Column -->
                <ng-container matColumnDef="action">
                    <th style="display: flex;justify-content: flex-end" mat-header-cell *matHeaderCellDef>Дія</th>
                    <td style="display: flex;justify-content: flex-end" mat-cell *matCellDef="let element">
                        <button mat-icon-button [routerLink]="['/main', 'advertisement', 'categories-home', 'edit', element._id]">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell without-data" [attr.colspan]="displayedColumns.length">
                        Категорії ще не створені
                    </td>
                </tr>
            </table>
            <mat-paginator [length]="totalRecords" [pageSize]="pageSize" (page)="onPageChange($event)" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
            </mat-paginator>
        </div>
    </mat-card-content>
</mat-card>