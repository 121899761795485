import { Component, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorService } from 'src/app/components/common/notifications/errors/error.service';
import { SuccessService } from 'src/app/components/common/notifications/success/success.service';
import { DEFAULT_MESSAGES } from 'src/app/constants/default-messages';
import { EndpointTypeEnum } from 'src/app/enums/endpoint-type.enum';
import { ErrorTypeEnum } from 'src/app/enums/error-type.enum';
import { ApiService } from 'src/app/services/api.service';


interface PeriodicElement {
    name_ua: string;
    parent_id: string;
    _id: string;
    attributes: Attribute[]
}

interface Attribute {
    name_ua: string;
    _id: string;
}


@Component({
    selector: 'app-import-settings',
    templateUrl: './import-settings.component.html',
    styleUrl: './import-settings.component.scss'
})

export class ImportSettingsComponent {
    displayedColumns: string[] = ['name', 'filters', 'action'];
    ELEMENT_DATA: PeriodicElement[] = [];
    dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
    attributesList: Attribute[] = [];

    totalRecords = 0;
    timeout: any = null;
    pageSizeOptions: any[] = [10];
    pageIndex: number = 0;
    pageSize: number = this.pageSizeOptions[0];
    urlParams = {
        search: null
    };

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('searchBlock') searchBlock: any;

    constructor(
        private router: Router,
        private apiService: ApiService,
        private route: ActivatedRoute,
        private errorService: ErrorService,
        private successService: SuccessService
    ) {

    }

    ngOnInit() {
        this.route.queryParams.subscribe((params: any) => {
            this.pageIndex = params['page'] ? +params['page'] - 1 : 0;
            this.urlParams = params;
        });
        this.loadAttributes();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.paginator.pageIndex = this.pageIndex;
            this.load();
        });
    }

    ngOnDestroy() {
        this.successService.clear(ErrorTypeEnum.Global);
        this.successService.clear(ErrorTypeEnum.Local);
        this.errorService.clearErrors(ErrorTypeEnum.Global);
        this.errorService.clearErrors(ErrorTypeEnum.Local);
    }

    load() {
        const search: string = this.searchBlock.nativeElement.value;
        this.apiService
            .get(EndpointTypeEnum.Main, '/import-by-categories/list', true, null, {
                'page': this.pageIndex,
                ...(search && { 'query': search })
            })
            .subscribe({
                next: (response: any) => {
                    // присваиваем данные переменной ELEMENT_DATA
                    this.ELEMENT_DATA = response.data;
                    // обновляем данные в MatTableDataSource
                    this.dataSource.data = this.ELEMENT_DATA;
                    // общее кол-во категорий
                    this.totalRecords = response.total;
                },
                error: (err) => {
                    this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
                },
            });
    }

    loadAttributes() {
        this.apiService
            .get(EndpointTypeEnum.Main, '/import-by-categories/list/attributes', true)
            .subscribe({
                next: (response: any) => {
                    this.attributesList = response.data
                },
                error: (err) => {
                    this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
                },
            })
    }

    save(id: string) {
        const savedItem = this.dataSource.data.find(item => item._id === id);
        const attributeId = savedItem?.attributes.map(filter => filter._id);
        this.apiService
            .put(EndpointTypeEnum.Main, '/import-by-categories/list/update', true, { ids: attributeId }, id)
            .subscribe({
                next: (response: any) => {
                    this.successService.add(ErrorTypeEnum.Local, [DEFAULT_MESSAGES.SUCCESS]);
                },
                error: (err: any) => {
                    this.errorService.addError(ErrorTypeEnum.Local, [err.error.message]);
                },
            });
    }

    onPageChange(event: PageEvent): void {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;

        this.changeURLAsMerge({
            page: this.pageIndex + 1
        })

        this.load();
    }

    onClearSearch() {
        this.searchBlock.nativeElement.value = '';
        this.pageIndex = 1;
        this.changeURLAsMerge({
            page: this.pageIndex,
            search: null
        })
        this.load();
    }

    applyFilter(event: any) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            if (event.key !== '13') {
                this.pageIndex = 0;
                this.paginator.pageIndex = this.pageIndex;
                this.changeURLAsMerge({
                    page: 1,
                    search: event.target.value
                })
                this.load();
            }
        }, 1000);
    }

    changeURLAsMerge(json: any, preserve?: any) {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: json,
            queryParamsHandling: preserve || 'merge'
        });
    }
}
