import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ErrorService } from 'src/app/components/common/notifications/errors/error.service';
import { SuccessService } from 'src/app/components/common/notifications/success/success.service';
import { DEFAULT_MESSAGES } from 'src/app/constants/default-messages';
import { EndpointTypeEnum } from 'src/app/enums/endpoint-type.enum';
import { ErrorTypeEnum } from 'src/app/enums/error-type.enum';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-home-categories-create',
    templateUrl: './home-categories-create.component.html',
    styleUrl: './home-categories-create.component.scss'
})
export class HomeCategoriesCreateComponent implements OnDestroy {
    form!: FormGroup;
    formWasSubmitted = false;

    categoriesForDropdown: any;
    addedCategories: any[] = [];
    addedBannerListUA: any[] = [];
    addedBannerListRU: any[] = [];
    addedBannerListMob: any[] = [{
        image_ua: '',
        image_ru: '',
        link_ua: '',
        link_ru: ''
    }];
    backendUrl: string;

    @ViewChild('input') input: ElementRef<HTMLInputElement>;

    // для блока "Вибір товарів"
    optionsControlForViewedWith = new FormControl('');
    optionsForViewedWith: any[] = [];
    selectedOptions: any[] = [];
    filteredOptions: any[] = [];
    googleMerchant: any;
    selectedCategoriesForMerchant: string[] = [];

    constructor(
        protected formBuilder: FormBuilder,
        protected apiService: ApiService,
        protected errorService: ErrorService,
        protected successService: SuccessService,
        protected router: Router
    ) {
        this.backendUrl = environment.backendUrl;
        this.initForm();
    }

    ngOnDestroy() {
        this.successService.clear(ErrorTypeEnum.Global);
        this.successService.clear(ErrorTypeEnum.Local);
        this.errorService.clearErrors(ErrorTypeEnum.Global);
        this.errorService.clearErrors(ErrorTypeEnum.Local);
    }

    // инициализация формы
    initForm(): void {
        // инициализируем поля из формы и валидируем
        this.form = this.formBuilder.group({
            header_ru: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
            header_ua: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
            sort: [Number(1), [Validators.required]],
            link_show_all_ua: ['', [Validators.required]],
            link_show_all_ru: ['', [Validators.required]]
        });
    }

    // -- логика для: Вибір товарів --
    onUpdateOptions(event: any) {
        const selectedOption = event.option.value;
        const item = {
            _id: selectedOption._id,
            product: {
                name_ua: selectedOption.product.name_ua,
            }
        }
        const exist = this.selectedOptions.find(option => option._id === item._id);
        if (!exist) {
            this.selectedOptions.push(item);
        }
        this.input.nativeElement.value = '';
        this.filteredOptions = [];
    }

     // -- логика для: Вибір товарів --
    removeOption(index: number) {
        this.selectedOptions.splice(index, 1);
    }

    
    uploadImage(index: number, event: any, type: 'image_pc_ua' | 'image_pc_ru' | 'image_mob_ua' | 'image_mob_ru'): void {
        const filesList: FileList = event.target.files;
        const files = Array.from(filesList);
        const formData = new FormData();

        formData.append('image', files[0]);
        this.apiService
            .postMultipartFormData(EndpointTypeEnum.Requests, '/upload-files', true, formData, true)
            .subscribe({
                next: (response: any) => {
                    if (type === 'image_pc_ua') {
                        this.addedBannerListUA[index].image = response.data;
                    } else if (type === 'image_pc_ru') {
                        this.addedBannerListRU[index].image = response.data;
                    } else if (type === 'image_mob_ua') {
                        this.addedBannerListMob[index].image_ua = response.data;
                    } else if (type === 'image_mob_ru') {
                        this.addedBannerListMob[index].image_ru = response.data;
                    }
                    this.errorService.clearErrors(ErrorTypeEnum.Local);
                },
                error: (err: any) => {
                    this.errorService.addError(ErrorTypeEnum.Local, [err.error.message]);
                },
            });
    }

    onUpdateBanner(index: number, field: string, event: any, lang?: string): void {
        const langCode = lang?.toUpperCase();
        switch (field) {
            case 'link_ua':
                // записываем значение поля
                this.addedBannerListMob[index].link_ua = event.target.value;
                break;
            case 'link_ru':
                // записываем значение поля
                this.addedBannerListMob[index].link_ru = event.target.value;
                break;
            case 'link':
                // записываем значение поля
                (<any>this)['addedBannerList' + langCode][index].link = event.target.value;
                break;
            case 'position':
                // записываем значение поля
                (<any>this)['addedBannerList' + langCode][index].position = event.target.value;
                break;
            default:
                break;
        }
    }

    checkBannerPosition() {
        const arrays = [this.addedBannerListUA, this.addedBannerListRU]

        for (const items of arrays) {
            const positions = new Set();
            for (const item of items) {
                const position = Number(item.position)
                if (positions.has(position)) {
                    return true
                }
                positions.add(position);
            }
        }
        return false;
    }

    removeBanner(index: number, lang: string) {
        const langCode = lang.toUpperCase();
        (<any>this)['addedBannerList' + langCode].splice(index, 1);
    }

    addBanner(lang: string) {
        const langCode = lang.toUpperCase();
        (<any>this)['addedBannerList' + langCode].push({
            image: '',
            link: '',
            position: ''
        })
    }

    getOption(option: string | number) {
        if (option) {
            this.apiService.get(EndpointTypeEnum.Main, '/ads/categories-home/options', true, null, { query: option })
                .subscribe({
                    next: (response: any) => {
                        this.filteredOptions = response.data
                    },
                    error: (err) => {
                        this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
                    },
                })
        }
    }

     // отправка формы
    onFormSubmit(form: FormGroup): void {
        this.formWasSubmitted = true;
        
        // проверка на дубликаты в позициях баннеров
        if (this.checkBannerPosition()) {
            this.errorService.addError(ErrorTypeEnum.Local, ['Позиції повинні відрізнятись. Перевірте уважно форму']);
            return;
        } else {
            this.errorService.clearErrors(ErrorTypeEnum.Local);
        }
        

        // если есть ошибки валидации в полях формы, не даем отправить форму
        if (form.invalid) {
            this.errorService.addError(ErrorTypeEnum.Local, [DEFAULT_MESSAGES.FORM_ERROR]);
            return;
        }

        // получаем значения из формы
        const formValue = form.value;
        formValue.sort = Number(formValue.sort)
        formValue.options = this.selectedOptions.map(item => item._id);
        formValue.banners_pc_ua = this.addedBannerListUA;
        formValue.banners_pc_ru = this.addedBannerListRU;
        formValue.banners_mob = this.addedBannerListMob;

        // отправка формы
        this.apiService
            .post(EndpointTypeEnum.Main, '/ads/categories-home', true, formValue)
            .subscribe({
                next: (response: any) => {
                    this.router.navigate(['/main', 'advertisement', 'categories-home']);
                    this.successService.add(ErrorTypeEnum.Local, [DEFAULT_MESSAGES.SUCCESS]);
                },
                error: (err: any) => {
                    this.errorService.addError(ErrorTypeEnum.Local, [err.error.message]);
                },
            });
    }

}
