<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Товари</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Дашборд
            </a>
        </li>
        <li class="breadcrumb-item gray-color">Каталог</li>
        <li class="breadcrumb-item gray-color">Товари</li>
        <li class="breadcrumb-item fw-semibold">Створення товара</li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card">
    <mat-card-header>
        <h5 class="mb-0">Створення товара</h5>
        <div class="d-flex align-items-center">
            <button mat-raised-button class="tagus small margin-right-10px" form="form" type="submit">Зберегти</button>
            <a class="small" mat-stroked-button [routerLink]="['/market', market._id, 'products']" color="warn">Вийти</a>
        </div>
    </mat-card-header>
    <mat-card-content>
        <app-local-error></app-local-error>
        <app-modal-confirmation *ngIf="showConfirmation" (confirmed)="onConfirmationRemoveImage($event)"></app-modal-confirmation>
        <form [formGroup]="form" (submit)="onFormSubmit(form)" id="form">
            <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
                <!-- Загальне -->
                <mat-tab label="Загальне"><br>
                    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
                        <!-- УКР -->
                        <mat-tab label="УКР"><br>

                            <mat-form-field class="width-100">
                                <mat-label>Назва</mat-label>
                                <input formControlName="name_ua" matInput (ngModelChange)="subscribeToFieldChanges('name_ua', 'url', $event)">
                                <mat-error *ngIf="(formWasSubmitted && form.controls['name_ua'].invalid) || (form.controls['name_ua'].invalid && form.controls['name_ua'].touched)">
                                    <ng-container *ngIf="form.controls['name_ua'].errors?.['required']">
                                        Обов'язкове поле
                                    </ng-container>
                                    <ng-container *ngIf="form.controls['name_ua'].errors?.['minlength']">
                                        Поле може містити мінімум 2 символи
                                    </ng-container>
                                    <ng-container *ngIf="form.controls['name_ua'].errors?.['maxlength']">
                                        Поле може містити максимум 255 символів
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>

                            <div class="width-100" style="padding-bottom: 20px;">
                                <label class="d-block mb-12 fw-semibold gray-color">Опис</label>
                                <angular-editor formControlName="description_ua" [config]="editorConfig"></angular-editor>
                            </div>

                            <mat-form-field class="width-100">
                                <mat-label>Мета title</mat-label>
                                <input formControlName="meta_title_ua" matInput>
                                <mat-error *ngIf="(formWasSubmitted && form.controls['meta_title_ua'].invalid) || (form.controls['meta_title_ua'].invalid && form.controls['meta_title_ua'].touched)">
                                    <ng-container *ngIf="form.controls['meta_title_ua'].errors?.['minlength']">
                                        Поле може містити мінімум 2 символи
                                    </ng-container>
                                    <ng-container *ngIf="form.controls['meta_title_ua'].errors?.['maxlength']">
                                        Поле може містити максимум 80 символів
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="width-100">
                                <mat-label>Мета description</mat-label>
                                <textarea formControlName="meta_description_ua" rows="5" matInput></textarea>
                                <mat-error *ngIf="(formWasSubmitted && form.controls['meta_description_ua'].invalid) || (form.controls['meta_description_ua'].invalid && form.controls['meta_description_ua'].touched)">
                                    <ng-container *ngIf="form.controls['meta_description_ua'].errors?.['minlength']">
                                        Поле може містити мінімум 2 символи
                                    </ng-container>
                                    <ng-container *ngIf="form.controls['meta_description_ua'].errors?.['maxlength']">
                                        Поле може містити максимум 255 символів
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>

                        </mat-tab>
                        <mat-tab label="РУС"><br>

                            <mat-form-field class="width-100">
                                <mat-label>Назва</mat-label>
                                <input formControlName="name_ru" matInput>
                                <mat-error *ngIf="(formWasSubmitted && form.controls['name_ru'].invalid) || (form.controls['name_ru'].invalid && form.controls['name_ru'].touched)">
                                    <ng-container *ngIf="form.controls['name_ru'].errors?.['required']">
                                        Обов'язкове поле
                                    </ng-container>
                                    <ng-container *ngIf="form.controls['name_ru'].errors?.['minlength']">
                                        Поле може містити мінімум 2 символи
                                    </ng-container>
                                    <ng-container *ngIf="form.controls['name_ru'].errors?.['maxlength']">
                                        Поле може містити максимум 255 символів
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>

                            <div class="width-100" style="padding-bottom: 20px;">
                                <label class="d-block mb-12 fw-semibold gray-color">Опис</label>
                                <angular-editor formControlName="description_ru" [config]="editorConfig"></angular-editor>
                            </div>

                            <mat-form-field class="width-100">
                                <mat-label>Мета title</mat-label>
                                <input formControlName="meta_title_ru" matInput>
                                <mat-error *ngIf="(formWasSubmitted && form.controls['meta_title_ru'].invalid) || (form.controls['meta_title_ru'].invalid && form.controls['meta_title_ru'].touched)">
                                    <ng-container *ngIf="form.controls['meta_title_ru'].errors?.['minlength']">
                                        Поле може містити мінімум 2 символи
                                    </ng-container>
                                    <ng-container *ngIf="form.controls['meta_title_ru'].errors?.['maxlength']">
                                        Поле може містити максимум 80 символів
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="width-100">
                                <mat-label>Мета description</mat-label>
                                <textarea formControlName="meta_description_ru" rows="5" matInput></textarea>
                                <mat-error *ngIf="(formWasSubmitted && form.controls['meta_description_ru'].invalid) || (form.controls['meta_description_ru'].invalid && form.controls['meta_description_ru'].touched)">
                                    <ng-container *ngIf="form.controls['meta_description_ru'].errors?.['minlength']">
                                        Поле може містити мінімум 2 символи
                                    </ng-container>
                                    <ng-container *ngIf="form.controls['meta_description_ru'].errors?.['maxlength']">
                                        Поле може містити максимум 255 символів
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>

                        </mat-tab>
                    </mat-tab-group>
                </mat-tab>
                <!-- Дані -->
                <mat-tab label="Дані"><br>
                    <div class="row" style="margin: 0">
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <mat-form-field class="width-100">
                                <mat-label>Ціна</mat-label>
                                <input formControlName="price" matInput>
                                <mat-error *ngIf="(formWasSubmitted && form.controls['price'].invalid) || (form.controls['price'].invalid && form.controls['price'].touched)">
                                    <ng-container *ngIf="form.controls['price'].errors?.['required']">
                                        Обов'язкове поле
                                    </ng-container>
                                    <ng-container *ngIf="form.controls['price'].errors?.['minlength']">
                                        Поле може містити мінімум 2 символи
                                    </ng-container>
                                    <ng-container *ngIf="form.controls['price'].errors?.['invalidPrice']">
                                        Неправильний формат ціни.
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <mat-form-field class="width-100">
                                <mat-label>Ціна зі знижкою</mat-label>
                                <input formControlName="price_new" matInput>
                                <mat-error *ngIf="(formWasSubmitted && form.controls['price_new'].invalid) || (form.controls['price_new'].invalid && form.controls['price_new'].touched)">
                                    <ng-container *ngIf="form.controls['price_new'].errors?.['minlength']">
                                        Поле може містити мінімум 2 символи
                                    </ng-container>
                                    <ng-container *ngIf="form.controls['price_new'].errors?.['invalidPrice']">
                                        Неправильний формат ціни.
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <mat-form-field class="width-100">
                                <mat-label>Ціна закупки</mat-label>
                                <input formControlName="purchase_price" matInput>
                                <mat-error *ngIf="(formWasSubmitted && form.controls['purchase_price'].invalid) || (form.controls['purchase_price'].invalid && form.controls['purchase_price'].touched)">
                                    <ng-container *ngIf="form.controls['purchase_price'].errors?.['minlength']">
                                        Поле може містити мінімум 2 символи
                                    </ng-container>
                                    <ng-container *ngIf="form.controls['purchase_price'].errors?.['invalidPrice']">
                                        Неправильний формат ціни.
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <mat-form-field appearance="fill" class="width-100">
                                <mat-label>Одиниця виміру</mat-label>
                                <mat-select formControlName="unit">
                                    <mat-option *ngFor="let unit of unitsForDropdown" [value]="unit._id">
                                        {{unit.name_ua}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <mat-form-field appearance="fill" class="width-100">
                                <mat-label>Новинка</mat-label>
                                <mat-select formControlName="is_new">
                                    <mat-option [value]="1">Так</mat-option>
                                    <mat-option [value]="0">Нi</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <mat-form-field appearance="fill" class="width-100">
                                <mat-label>Товар тижня</mat-label>
                                <mat-select formControlName="is_product_of_week">
                                    <mat-option [value]="1">Так</mat-option>
                                    <mat-option [value]="0">Нi</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <mat-form-field class="width-100">
                                <mat-label>Кількість</mat-label>
                                <input formControlName="amount" matInput>
                                <mat-error *ngIf="(formWasSubmitted && form.controls['amount'].invalid) || (form.controls['amount'].invalid && form.controls['amount'].touched)">
                                    <ng-container *ngIf="form.controls['amount'].errors?.['pattern']">
                                        Поле може містити тільки цифри
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <mat-form-field appearance="fill" class="width-100">
                                <mat-label>Наявність</mat-label>
                                <mat-select formControlName="availability">
                                    <mat-option [value]="1">Є в наявності</mat-option>
                                    <mat-option [value]="0">Немає в наявності</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <mat-form-field class="width-100">
                                <mat-label>Артикул</mat-label>
                                <input formControlName="article" matInput>
                                <mat-error *ngIf="(formWasSubmitted && form.controls['article'].invalid) || (form.controls['article'].invalid && form.controls['article'].touched)">
                                    <ng-container *ngIf="form.controls['article'].errors?.['required']">
                                        Обов'язкове поле
                                    </ng-container>
                                    <ng-container *ngIf="form.controls['article'].errors?.['minlength']">
                                        Поле може містити мінімум 2 символи
                                    </ng-container>
                                    <ng-container *ngIf="form.controls['article'].errors?.['maxlength']">
                                        Поле може містити максимум 255 символів
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <mat-form-field class="width-100">
                                <mat-label>UPC</mat-label>
                                <input formControlName="upc" matInput>
                                <mat-error *ngIf="(formWasSubmitted && form.controls['upc'].invalid) || (form.controls['upc'].invalid && form.controls['upc'].touched)">
                                    <ng-container *ngIf="form.controls['upc'].errors?.['minlength']">
                                        Поле може містити мінімум 2 символи
                                    </ng-container>
                                    <ng-container *ngIf="form.controls['upc'].errors?.['maxlength']">
                                        Поле може містити максимум 40 символів
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <mat-form-field class="width-100">
                                <mat-label>EAN</mat-label>
                                <input formControlName="ean" matInput>
                                <mat-error *ngIf="(formWasSubmitted && form.controls['ean'].invalid) || (form.controls['ean'].invalid && form.controls['ean'].touched)">
                                    <ng-container *ngIf="form.controls['ean'].errors?.['minlength']">
                                        Поле може містити мінімум 2 символи
                                    </ng-container>
                                    <ng-container *ngIf="form.controls['ean'].errors?.['maxlength']">
                                        Поле може містити максимум 40 символів
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <mat-form-field class="width-100">
                                <mat-label>JAN</mat-label>
                                <input formControlName="jan" matInput>
                                <mat-error *ngIf="(formWasSubmitted && form.controls['jan'].invalid) || (form.controls['jan'].invalid && form.controls['jan'].touched)">
                                    <ng-container *ngIf="form.controls['jan'].errors?.['minlength']">
                                        Поле може містити мінімум 2 символи
                                    </ng-container>
                                    <ng-container *ngIf="form.controls['jan'].errors?.['maxlength']">
                                        Поле може містити максимум 40 символів
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <mat-form-field class="width-100">
                                <mat-label>MPN</mat-label>
                                <input formControlName="mpn" matInput>
                                <mat-error *ngIf="(formWasSubmitted && form.controls['mpn'].invalid) || (form.controls['mpn'].invalid && form.controls['mpn'].touched)">
                                    <ng-container *ngIf="form.controls['mpn'].errors?.['minlength']">
                                        Поле може містити мінімум 2 символи
                                    </ng-container>
                                    <ng-container *ngIf="form.controls['mpn'].errors?.['maxlength']">
                                        Поле може містити максимум 40 символів
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <mat-form-field appearance="fill" class="width-100">
                            <mat-label>Статус</mat-label>
                            <mat-select formControlName="status">
                                <mat-option [value]="1">Активно</mat-option>
                                <mat-option [value]="0">Не активно</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </mat-tab>
                <!-- Зв'язки -->
                <mat-tab label="Зв'язки"><br>
                    <div class="row" style="margin: 0;padding: 0">
                    <mat-form-field appearance="fill" class="width-100">
                        <mat-label>Головна категорія</mat-label>
                        <mat-select formControlName="main_category">
                            <mat-option *ngFor="let category of categoriesForDropdown" [value]="category._id">
                                {{category.name_ua}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div class="width-100" *ngIf="categoriesForDropdown && categoriesForDropdown.length > 0">
                        <mat-label>Показувати у категоріях</mat-label>
                        <div style="max-height: 200px;overflow-y: auto">
                        <ng-container *ngFor="let category of categoriesForDropdown">
                            <mat-checkbox
                                class="example-margin"
                                style="display: block"
                                [value]="category._id"
                                (change)="updateSelectedCategories(category._id)"
                                [checked]="isSelectedCategories(category._id)"
                            >
                                {{ category.name_ua }}
                            </mat-checkbox>
                        </ng-container>
                        </div>
                    </div>

                        <hr/>
                        <mat-form-field class="width-100">
                        <mat-label>З цими товарами дивляться</mat-label>
                        <input #input
                               type="text"
                               placeholder=""
                               matInput
                               [formControl]="optionsControlForViewedWith"
                               [matAutocomplete]="auto"
                               (input)="filterOptionsViewedWith()"
                               (focus)="filterOptionsViewedWith()">
                        <mat-autocomplete requireSelection #auto="matAutocomplete" (optionSelected)="onUpdateOptionsViewedWith($event)">
                            <mat-option *ngFor="let option of filteredOptionsViewedWith" [value]="option">
                                {{ option.value_ua }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <ng-container *ngFor="let selectedOption of selectedOptionsViewedWith; let i = index">
                        <span style="cursor: pointer" (click)="removeOptionViewedWith(i)">
                            ❌ {{ selectedOption.value_ua }}
                        </span>
                        <br/>
                    </ng-container>
                    </div>
                </mat-tab>
                <!-- Атрибути -->
                <mat-tab label="Атрибути"><br>
                    <div *ngFor="let addedAttribute of addedAttributes; let i = index">
                        <div class="row" style="margin: 0">
                            <div class="col-lg-4 col-md-4 col-sm-4">
                                <app-filter-input-autocomplete
                                    [label]="'Атрибут'"
                                    [value]="addedAttributes[i]"
                                    [dropdownList]="attributesForDropdown"
                                    (selection)="onUpdateAttribute(i, 'attribute', $event)">
                                </app-filter-input-autocomplete>
                                <div *ngIf="formWasSubmitted && !fieldAddedAttributesInAttributes[i].isValid" class="alert alert-danger" role="alert">
                                    Обов'язкове поле
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-4">
                                <mat-form-field class="width-100">
                                    <mat-label>Значення (укр)</mat-label>
                                    <input matInput (change)="onUpdateAttribute(i, 'name_ua', $event)">
                                </mat-form-field>
                                <div *ngIf="formWasSubmitted && !fieldNameUaInAttributes[i].isValid" class="alert alert-danger" role="alert">
                                    Обов'язкове поле
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-4">
                                <mat-form-field class="width-100">
                                    <mat-label>Значення (рус)</mat-label>
                                    <input matInput (change)="onUpdateAttribute(i, 'name_ru', $event)" placeholder="Название (рус)">
                                </mat-form-field>
                                <div *ngIf="formWasSubmitted && !fieldNameRuInAttributes[i].isValid" class="alert alert-danger" role="alert">
                                    Обов'язкове поле
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-4">
                                <button mat-stroked-button color="warn" (click)="removeAttribute(i)">Видалити атрибут</button>
                            </div>
                        </div>
                        <hr/>
                    </div>

                    <div class="row" style="margin: 0;">
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <button type="button" mat-raised-button class="tagus" (click)="addAttribute()">Додати</button>
                        </div>
                    </div>
                </mat-tab>
                <!-- Опції -->
                <mat-tab label="Опції"><br>
                    <div class="row" style="margin: 0;">
                        <div class="col-lg-12 col-md-12 col-sm-12" style="text-align: right;">
                            <button type="button" mat-raised-button class="tagus" (click)="addOption()">Додати</button>
                            <br />
                        </div>
                    </div>
                    <cdk-accordion class="option-accordion-list">
                        @for (addedOption of addedOptions; track addedOption; let i = $index) {
                        <cdk-accordion-item #accordionItem="cdkAccordionItem" class="option-accordion-item">
                            <div
                                class="option-accordion-item__header"
                                tabindex="0"
                                [attr.id]="'accordion-header-' + i"
                                [attr.aria-expanded]="accordionItem.expanded"
                                [attr.aria-controls]="'accordion-body-' + i">
                                <span *ngIf="!addedOptions[i].main_option[0]?.main_option?.name_ua || !addedOptions[i].main_option[0]?.value_ua">Опція</span>
                                <span *ngIf="addedOptions[i].main_option[0]?.main_option?.name_ua && addedOptions[i].main_option[0]?.value_ua">
                                    @for (item_option of addedOptions[i].main_option; track item_option; let index = $index; let last = $last) {
                                            {{ item_option.main_option.name_ua }}: {{ item_option.value_ua }}<ng-container *ngIf="!last">, </ng-container>
                                    }
                                </span>
                                <span class="option-accordion-item__btn">
                                    <button type="button" mat-mini-fab color="warn" (click)="removeOption(i)">
                                        <mat-icon>delete_outline</mat-icon>
                                    </button>
                                    <button type="button" mat-mini-fab class="tagus" (click)="accordionItem.toggle()">
                                        <mat-icon>expand_more</mat-icon>
                                    </button>
                                </span>
                            </div>
                            @if(accordionItem.expanded) {
                            <div
                                class="option-accordion-item__body"
                                role="region"
                                [style.display]="accordionItem.expanded ? '' : 'none'"
                                [attr.id]="'accordion-body-' + i"
                                [attr.aria-labelledby]="'accordion-header-' + i"
                            >
                                <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
                                    <!-- Опція -->
                                    <mat-tab label="Опція"><br>
                                        <div class="row" style="margin: 0">
                                            @for (item_option of addedOptions[i].main_option; track item_option; let index = $index) {
                                                <div class="option-line">
                                                    <div class="option-line__item">
                                                        <div class="row" style="margin: 0">
                                                            <div class="col-lg-4 col-md-4 col-sm-4">
                                                                <mat-form-field appearance="fill" class="width-100">
                                                                    <mat-label>Опція</mat-label>
                                                                    <mat-select [value]="item_option.main_option" [compareWith]="compareObjects" (selectionChange)="onUpdateOption(i, 'main_option', $event, '', index)">
                                                                        <mat-option *ngFor="let option of optionsForDropdown" [value]="option">
                                                                            {{option.name_ua}}
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>
                                                                <div *ngIf="formWasSubmitted && !fieldAddedOptionsInOptions[i].main_option[index]._id" class="alert alert-danger" role="alert">
                                                                    Обов'язкове поле
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4 col-sm-4">
                                                                <mat-form-field class="width-100">
                                                                    <mat-label>Значення (укр)</mat-label>
                                                                    <!--                                    <input matInput (change)="onUpdateOption(i, 'name_ua', $event); subscribeToFieldChanges('name_ua-' + i, 'url-' + i, $event)">-->
                                                                    <input matInput (change)="onUpdateOption(i, 'name_ua', $event, '', index)" [value]="item_option.value_ua || null">
                                                                </mat-form-field>
                                                                <div *ngIf="formWasSubmitted && !fieldAddedOptionsInOptions[i].main_option[index].value_ua" class="alert alert-danger" role="alert">
                                                                    Обов'язкове поле
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4 col-sm-4">
                                                                <mat-form-field class="width-100">
                                                                    <mat-label>Значення (рус)</mat-label>
                                                                    <input matInput (change)="onUpdateOption(i, 'name_ru', $event, '', index)" [value]="item_option.value_ru || null">
                                                                </mat-form-field>
                                                                <div *ngIf="formWasSubmitted && !fieldAddedOptionsInOptions[i].main_option[index].value_ru" class="alert alert-danger" role="alert">
                                                                    Обов'язкове поле
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="option-line__btn">
                                                        <button type="button" mat-mini-fab class="tagus" (click)="removeOneOption(i, index)">
                                                            <mat-icon>delete</mat-icon>
                                                        </button>
                                                    </div>
                                                </div>
                                            }
                                            <div class="col-lg-4 col-md-4 col-sm-4" style="padding: 0;">
                                                <button type="button" mat-raised-button class="tagus" (click)="addOptionOne(i)">Додати опцію</button>
                                            </div>
                                        </div>
                                    </mat-tab>

                                    <!-- Основне -->
                                    <mat-tab label="Основне"><br>
                                        <div class="row" style="margin: 0">
                                            <div class="col-lg-4 col-md-4 col-sm-4">
                                                <mat-form-field class="width-100">
                                                    <mat-label>Ціна</mat-label>
                                                    <input matInput (change)="onUpdateOption(i, 'price', $event)" [value]="addedOptions[i].price || null">
                                                </mat-form-field>
                                                <div *ngIf="formWasSubmitted && !fieldAddedOptionsInOptions[i].price" class="alert alert-danger" role="alert">
                                                    Обов'язкове поле
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-sm-4">
                                                <mat-form-field class="width-100">
                                                    <mat-label>Ціна зі знижкою</mat-label>
                                                    <input matInput (change)="onUpdateOption(i, 'price_new', $event)" [value]="addedOptions[i].price_new || null">
                                                </mat-form-field>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-sm-4">
                                                <mat-form-field class="width-100">
                                                    <mat-label>Ціна закупки</mat-label>
                                                    <input matInput (change)="onUpdateOption(i, 'purchase_price', $event)" [value]="addedOptions[i].purchase_price || null">
                                                </mat-form-field>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-sm-4">
                                                <mat-form-field class="width-100">
                                                    <mat-label>Артикул</mat-label>
                                                    <input matInput (change)="onUpdateOption(i, 'article', $event)" [value]="addedOptions[i].article">
                                                </mat-form-field>
                                                <div *ngIf="formWasSubmitted && !fieldAddedOptionsInOptions[i].article" class="alert alert-danger" role="alert">
                                                    Обов'язкове поле
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-sm-4">
                                                <mat-form-field appearance="fill" class="width-100">
                                                    <mat-label>Наявність</mat-label>
                                                    <mat-select [(value)]="addedOptions[i].availability" (selectionChange)="onUpdateOption(i, 'availability', $event)">
                                                        <mat-option [value]="true">Є в наявності</mat-option>
                                                        <mat-option [value]="false">Немає в наявності</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-sm-4">
                                                <mat-form-field appearance="fill" class="width-100">
                                                    <mat-label>Статус</mat-label>
                                                    <mat-select [value]="addedOptions[i].status" (selectionChange)="onUpdateOption(i, 'status', $event)">
                                                        <mat-option [value]="true">Активно</mat-option>
                                                        <mat-option [value]="false">Не активно</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <!-- <div class="col-lg-4 col-md-4 col-sm-4">
                                                <button mat-stroked-button color="warn" (click)="removeOption(i)">Видалити опцію</button>
                                            </div> -->
                                        </div>
                                    </mat-tab>
            
                                    <!-- Додатково -->
                                    <mat-tab label="Додатково"><br>
                                        <div class="row" style="margin: 0">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <mat-form-field class="width-100">
                                                    <mat-label>Це кольорова опцiя?</mat-label>
                                                    <mat-select [(value)]="addedOptions[i].is_color" (selectionChange)="onUpdateOption(i, 'is_color', $event)">
                                                        <mat-option [value]="false">Hi</mat-option>
                                                        <mat-option [value]="true">Так</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <mat-form-field class="width-100">
                                                    <mat-label>Колiр</mat-label>
                                                    <input type="color" [value]="addedOptions[i].color || '#000'" matInput (change)="onUpdateOption(i, 'color', $event)">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </mat-tab>
            
                                    <!-- Зображення -->
                                    <mat-tab label="Зображення"><br>
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <label class="d-block mb-12 fw-semibold gray-color">Зображення</label>
                                            <input type="file" (change)="uploadImage($event, 'mainGalleryOptionImage', i)" accept="image/*">
                                            <!-- отображение изображения -->
                                            <ng-container *ngIf="addedOptions[i].mainImageOption">
                                                <br/>
                                                <div class="image-block mt-15 border-radius">
                                                    <a (click)="removeImage(addedOptions[i].mainImageOption._id)" class="remove-image" title="Видалити зображення">
                                                        <i class="flaticon-close"></i>
                                                    </a>
                                                    <img [src]="utilityService.addSuffixToImageUrl(backendUrl + '/uploads/' + addedOptions[i].mainImageOption.image, '60')">
                                                </div>
                                            </ng-container>
                                        </div>
                    
                                        <div class="col-lg-12 col-md-12 col-sm-12" style="margin-top: 20px;">
                                            <label class="d-block mb-12 fw-semibold gray-color">Додаткові зображення</label>
                                            <input type="file" (change)="uploadImage($event, 'secondaryGalleryOptionImages', i)" accept="image/*" multiple>
                                            <!-- отображение изображений -->
                                            <ng-container *ngIf="addedOptions[i].secondaryImageOption">
                                                <br/>
                                                <div class="image-block__list">
                                                    <ng-container *ngFor="let image of addedOptions[i].secondaryImageOption">
                                                        <div class="image-block mt-15 border-radius">
                                                            <a (click)="removeImage(image._id)" class="remove-image" title="Видалити зображення">
                                                                <i class="flaticon-close"></i>
                                                            </a>
                                                            <img [src]="utilityService.addSuffixToImageUrl(backendUrl + '/uploads/' + image.image, '60')">
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </mat-tab>
            
                                    <!-- SEO -->
                                    <mat-tab label="SEO"><br>
                                        <div class="row" style="margin: 0">
                                            <div class="col-lg-4 col-md-4 col-sm-4">
                                                <mat-form-field class="width-100">
                                                    <mat-label>URL</mat-label>
                                                    <input matInput (change)="onUpdateOption(i, 'url', $event)" [value]="addedOptions[i].url || null">
                                                </mat-form-field>
                                                <div *ngIf="formWasSubmitted && !fieldAddedOptionsInOptions[i].url" class="alert alert-danger" role="alert">
                                                    Обов'язкове поле
                                                </div>
                                            </div>
                                        </div>
                                    </mat-tab>
                                </mat-tab-group>
                            </div>
                            }
                        </cdk-accordion-item>
                        }
                    </cdk-accordion>
                </mat-tab>
                <!-- Зображення -->
                <mat-tab label="Зображення"><br>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <label class="d-block mb-12 fw-semibold gray-color">Зображення</label>
                        <input type="file" (change)="uploadImage($event, 'mainGalleryImage')" accept="image/*" formControlName="mainGalleryImage">
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-top: 20px;">
                        <label class="d-block mb-12 fw-semibold gray-color">Додаткові зображення</label>
                        <input type="file" (change)="uploadImage($event, 'secondaryGalleryImages')" accept="image/*" formControlName="secondaryGalleryImages" multiple>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-6" style="margin-top: 20px;">
                        <mat-form-field class="width-100">
                            <mat-label>Відео</mat-label>
                            <input formControlName="mainGalleryVideo" (change)="onUpdateVideo($event)" matInput>
                        </mat-form-field>
                    </div>

                </mat-tab>
                <!-- SEO -->
                <mat-tab label="SEO"><br>

                    <mat-form-field class="width-100">
                        <mat-label>URL</mat-label>
                        <input formControlName="url" matInput>
                        <mat-error *ngIf="(formWasSubmitted && form.controls['url'].invalid) || (form.controls['url'].invalid && form.controls['url'].touched)">
                            <ng-container *ngIf="form.controls['url'].errors?.['required']">
                                Обов'язкове поле
                            </ng-container>
                            <ng-container *ngIf="form.controls['url'].errors?.['pattern']">
                                Поле повинно містити тільки латинські літери, цифри та прочерки
                            </ng-container>
                            <ng-container *ngIf="form.controls['url'].errors?.['minlength']">
                                Поле може містити мінімум 2 символи
                            </ng-container>
                            <ng-container *ngIf="form.controls['url'].errors?.['maxlength']">
                                Поле може містити максимум 255 символів
                            </ng-container>
                        </mat-error>
                    </mat-form-field>

                </mat-tab>
                <!-- OpenGraph -->
                <mat-tab label="OpenGraph"><br>
                    <div class="row" style="margin: 0">

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <mat-form-field class="width-100">
                                <mat-label>Заголовок Facebook, Twitter (укр)</mat-label>
                                <input formControlName="opengraph_header_ua" matInput>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <mat-form-field class="width-100">
                                <mat-label>Заголовок Facebook, Twitter (рус)</mat-label>
                                <input formControlName="opengraph_header_ru" matInput>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <mat-form-field class="width-100">
                                <mat-label>Опис Facebook, Twitter (укр)</mat-label>
                                <textarea formControlName="opengraph_description_ua" rows="5" matInput></textarea>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <mat-form-field class="width-100">
                                <mat-label>Опис Facebook, Twitter (рус)</mat-label>
                                <textarea formControlName="opengraph_description_ru" rows="5" matInput></textarea>
                            </mat-form-field>
                        </div>

                        <div class="width-100">
                            <label class="d-block mb-12 fw-semibold gray-color">Зображення Facebook, Twitter</label>
                            <input type="file" (change)="uploadImage($event, 'openGraphImage')" accept="image/*" formControlName="opengraph_image">
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </form>
    </mat-card-content>
</mat-card>
