import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { map, Observable, startWith } from 'rxjs';

@Component({
    selector: 'app-filter-input-autocomplete',
    templateUrl: './filter-input-autocomplete.component.html',
    styleUrl: './filter-input-autocomplete.component.scss'
})
export class FilterInputAutocompleteComponent implements OnChanges {
    @Input() label: string;
    @Input() key: string;
    @Input() value: any;
    @Input() dropdownList: any;
    @Output() selection = new EventEmitter<any>();

    inputControl = new FormControl('');
    filteredOptions: Observable<any>;

    constructor() {
        this.filteredOptions = this.inputControl.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value || '')),
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        this.inputControl.setValue('')
        if (this.value) this.selectOption(this.value[this.key])
    }

    private _filter(value: any | string): any {
        const filterValue = typeof value === 'object' ? value.name_ua.toLowerCase() : value.toLowerCase();
        return this.dropdownList?.filter((option: any) => option?.name_ua?.toLowerCase().includes(filterValue));
    }

    displayFn(option: any): string {
        return option?.name_ua;
    }

    optionSelected(event: any) {
        this.selection.emit(event.option);
        this.selectOption(event.option.value)
    }

    selectOption(id: string) {
        const selectedAttr = this.dropdownList?.find((item: any) => item._id === id);
        this.inputControl.setValue(selectedAttr)
    }
}
