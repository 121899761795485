<form>
    <mat-form-field class="filters-block" appearance="fill">
        <mat-chip-grid #chipGrid aria-label="Вибір фільтрів" class="filters-list">
            <mat-chip-row class="filter-item" *ngFor="let data of selectedFilters" (removed)="remove(data._id)">
                <div class="filter-item__content">
                    {{data.name_ua}}
                    <button matChipRemove [attr.aria-label]="'remove ' + data.name_ua">
                        <mat-icon>cancel</mat-icon>
                    </button>
                </div>
            </mat-chip-row>
        </mat-chip-grid>
        <input 
            [placeholder]="placeholder" 
            #filterInput 
            [formControl]="filtrCtrl" 
            [matChipInputFor]="chipGrid" 
            [matAutocomplete]="auto" />
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            <mat-option *ngFor="let item of filteredList | async" [value]="item">
                {{item.name_ua}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</form>