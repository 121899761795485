import { Component, ElementRef, Input, output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { map, Observable, startWith } from 'rxjs';

@Component({
    selector: 'app-chips-list-autocomplete',
    templateUrl: './chips-list-autocomplete.component.html',
    styleUrl: './chips-list-autocomplete.component.scss'
})
export class ChipsListAutocompleteComponent {
    filtrCtrl = new FormControl('');
    filteredList: Observable<Filter[]>;
    @Input() selectedFilters: Filter[];
    @Input() filtersList: Filter[];
    @Input() categoryId: string;

    @ViewChild('filterInput') filterInput: ElementRef<HTMLInputElement>;


    constructor() {
        this.filteredList = this.filtrCtrl.valueChanges.pipe(
            startWith(null),
            map((item: string | null) => (item ? this._filter(item) : this.filtersList)),
        );
    }

    remove(filterId: string): void {
        const index = this.selectedFilters.findIndex(filter => filter._id === filterId)
        if (index >= 0) {
            this.selectedFilters.splice(index, 1);
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        const exist = this.selectedFilters.find(item => item._id === event.option.value._id);
        if (!exist) this.selectedFilters.push({name_ua: event.option.viewValue, _id: event.option.value._id});
        this.filterInput.nativeElement.value = '';
        this.filtrCtrl.setValue(null);
    }

    private _filter(value: Filter | string): Filter[] {
        const filterValue = typeof value === 'object' ? value.name_ua.toLowerCase() : value.toLowerCase();
        return this.filtersList.filter(filter => filter?.name_ua?.toLowerCase().includes(filterValue));
    }

}

interface Filter {
    name_ua: string;
    _id: string;
}
