import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {ApiService} from "../../../../services/api.service";
import {ErrorService} from "../../../common/notifications/errors/error.service";
import {SuccessService} from "../../../common/notifications/success/success.service";
import {ErrorTypeEnum} from "../../../../enums/error-type.enum";
import {EndpointTypeEnum} from "../../../../enums/endpoint-type.enum";
import {DEFAULT_MESSAGES} from "../../../../constants/default-messages";
import {UtilityService} from "../../../../services/utility.service";

@Component({
  selector: 'app-main-filter-edit',
  templateUrl: './main-filter-edit.component.html',
  styleUrl: './main-filter-edit.component.scss'
})
export class MainFilterEditComponent implements OnInit, OnDestroy {
    filter: any;
    filterId: any = this.route.snapshot.paramMap.get('id');
    form!: FormGroup;
    formWasSubmitted = false;
    categoriesForDropdown: any;
    addedFilterValues: any[] = [];
    selectedCategories: string[] = [];
    // для валидации полей при добавлении attribute value
    fieldValueStatusInFilterValues: any[] = [];
    fieldValueRuInFilterValues: any[] = [];
    fieldValueUaInFilterValues: any[] = [];

    constructor(
        private router: Router,
        private apiService: ApiService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private errorService: ErrorService,
        private successService: SuccessService,
        private utilityService: UtilityService,
    ) {
        this.getCategoriesForDropdown();
        this.initForm();
        this.subscribeToNameChanges();
    }

    // инициализация формы
    initForm(): void {
        // инициализируем поля из формы и валидируем
        this.form = this.formBuilder.group({
            name_ru:    ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
            name_ua:    ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
            url:        ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255), Validators.pattern(/^[a-z0-9-]+$/i)]],
            sort:       ['', [Validators.required, Validators.pattern(/^[0-9]+$/)]],
            status:     ['1', Validators.required],
            categories: [''],
        });
    }

    ngOnInit() {
        if (this.filterId !== null) {
            this.getFilter(this.filterId);
        }
    }

    ngOnDestroy() {
        this.successService.clear(ErrorTypeEnum.Global);
        this.successService.clear(ErrorTypeEnum.Local);
        this.errorService.clearErrors(ErrorTypeEnum.Global);
        this.errorService.clearErrors(ErrorTypeEnum.Local);
    }

    // -- логика для: filterValues --
    // обновление значений в полях
    onUpdateFilterValue(index: number, field: string, event: any): void {
        switch (field) {
            case 'status':
                // записываем значение поля status в addedFilterValues
                this.addedFilterValues[index].status = event.value;
                // записываем значение поля isValid в fieldValueStatusInFilterValues для дальнейшей валидации
                this.fieldValueStatusInFilterValues[index].isValid = !(event.value === '');
                break;
            case 'value_ru':
                // записываем значение поля value_ru в addedFilterValues
                this.addedFilterValues[index].value_ru = event.target.value;
                // записываем значение поля isValid в fieldNameRuInFilterValues для дальнейшей валидации
                this.fieldValueRuInFilterValues[index].isValid = !(event.target.value === '');
                break;
            case 'value_ua':
                // записываем значение поля value_ua в addedFilterValues
                this.addedFilterValues[index].value_ua = event.target.value;
                // записываем значение поля isValid в fieldNameUaInFilterValues для дальнейшей валидации
                this.fieldValueUaInFilterValues[index].isValid = !(event.target.value === '');
                break;
            default:
                break;
        }
    }

    // подписка на изменения в поле name для генерации url
    subscribeToNameChanges(): void {
        const nameField = this.form.get('name_ua');
        if (nameField) {
            nameField.valueChanges.subscribe(value => {
                // преобразуем значение в URL и устанавливаем его для поля "url"
                const urlField = this.form.get('url');
                if (urlField) {
                    urlField.setValue(this.utilityService.convertToUrl(value));
                }
            });
        }
    }

    // -- логика для: categories --
    // чекбокс для выбора категорий
    updateSelectedCategories(categoryId: string): void {
        if (this.isSelectedCategories(categoryId)) {
            this.selectedCategories = this.selectedCategories.filter(id => id !== categoryId);
        } else {
            this.selectedCategories.push(categoryId);
        }
    }

    // -- логика для: categories --
    // чекбокс для отображения выбранных категорий
    isSelectedCategories(categoryId: string): boolean {
        return this.selectedCategories.includes(categoryId);
    }

    // -- логика для: categories --
    // "Выбрать все значения"
    selectAllCategories(): void {
        this.selectedCategories = this.categoriesForDropdown.map((category: any) => category._id);
    }

    // "Снять все значения"
    clearAllCategories(): void {
        this.selectedCategories = [];
    }

    // получение данных для выпадающего списка
    getCategoriesForDropdown(): void {
        this.apiService
            .get(EndpointTypeEnum.DropdownLists, '/categories', true)
            .subscribe({
                next: (response: any) => {
                    this.categoriesForDropdown = response.data;
                },
                error: (err) => {
                    this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
                },
            });
    }

    // получение данных для формы
    getFilter(filterId: string) {
        this.apiService
            .get(EndpointTypeEnum.Main, '/filters', true, filterId)
            .subscribe({
                next: (response: any) => {
                    this.filter = response.data;
                    // добавляем заполненные чекбоксы для categories
                    // if (this.filter.categories && this.filter.categories.length > 0) {
                    this.selectedCategories = this.selectedCategories.concat(this.filter.categories.map((categories: any) => categories._id));
                    // }
                    // добавляем заполненные filter_values и поля валидации для них
                    for (const filterValue of this.filter.filter_values) {
                        this.addedFilterValues.push({
                            _id: filterValue._id,
                            value_ru: filterValue.value_ru,
                            value_ua: filterValue.value_ua,
                            status: filterValue.status,
                            name_ru: filterValue.name_ru,
                            name_ua: filterValue.name_ua,
                        });
                        const newIndex = this.addedFilterValues.length - 1;
                        this.fieldValueStatusInFilterValues[newIndex] = { isValid: true };
                        this.fieldValueRuInFilterValues[newIndex]     = { isValid: true };
                        this.fieldValueUaInFilterValues[newIndex]     = { isValid: true };
                    }
                    // установка значения полей
                    this.form.patchValue({
                        name_ru: this.filter.name_ru,
                        name_ua: this.filter.name_ua,
                        url: this.filter.url,
                        sort: this.filter.sort,
                        status: this.filter.status ? this.filter.status = 1 : this.filter.status = 0,
                    });
                },
                error: (err) => {
                    this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
                },
            });
    }

    // отправка формы
    onFormSubmit(form: FormGroup): void {
        this.formWasSubmitted = true;

        // если есть ошибки валидации в полях атрибутов, не даем отправить форму
        const allFieldFilterValuesArrays = [
            this.fieldValueStatusInFilterValues,
            this.fieldValueRuInFilterValues,
            this.fieldValueUaInFilterValues,
        ];
        for (const fieldArray of allFieldFilterValuesArrays) {
            for (const field of fieldArray) {
                if (!field.isValid) {
                    this.errorService.addError(ErrorTypeEnum.Local, [DEFAULT_MESSAGES.FORM_ERROR]);
                    return;
                }
            }
        }

        // если есть ошибки валидации, не даем отправить форму
        if (form.invalid) {
            return;
        }

        // получаем значения из формы
        const formValue = form.value;
        formValue.categories = this.selectedCategories;
        formValue.added_filter_values = this.addedFilterValues;
        formValue.sort = parseInt(formValue.sort, 10);

        // отправка формы
        this.apiService
            .put(EndpointTypeEnum.Main, '/filters', true, form.value, this.filterId)
            .subscribe({
                next: (response: any) => {
                    this.successService.add(ErrorTypeEnum.Local, [DEFAULT_MESSAGES.SUCCESS]);
                    this.addedFilterValues = [];
                    this.getFilter(this.filterId);
                },
                error: (err: any) => {
                    this.errorService.addError(ErrorTypeEnum.Local, [err.error.message]);
                },
            });
    }

    sync() {
        this.apiService
            .get(EndpointTypeEnum.Main, '/filters/compare', true, this.filterId)
            .subscribe({
                next: (response: any) => {
                    this.successService.add(ErrorTypeEnum.Local, [DEFAULT_MESSAGES.SUCCESS_SYNC]);
                    this.addedFilterValues = [];
                    this.getFilter(this.filterId);
                },
                error: (err) => {
                    this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
                },
            });
    }
}

