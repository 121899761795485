<div
    class="sidebar-area bg-white box-shadow"
    [ngClass]="{'active': isToggled}"
    [class.dark-sidebar]="themeService.isSidebarDark()"
    [class.right-sidebar]="themeService.isRightSidebar()"
    [class.hide-sidebar]="themeService.isHideSidebar()"
>
    <div class="logo bg-white">
        <a routerLink="/" class="d-flex align-items-center">
            <img src="assets/img/albatros/logo-dark.svg">
        </a>
    </div>
    <div class="burger-menu" (click)="toggle()" [ngClass]="{'active': isToggled}">
        <span class="top-bar"></span>
        <span class="middle-bar"></span>
        <span class="bottom-bar"></span>
    </div>
    <ng-scrollbar visibility="hover" style="height: 100vh;">
        <div class="sidebar-inner">
            <div class="sidebar-menu">
                <mat-accordion>
                    <ng-container *ngIf="currentPage.startsWith('/main')">
                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <i class="flaticon-menu-1"></i>
                                    <span class="title">Каталог</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ul class="sidebar-sub-menu">
                                <li class="sidemenu-item">
                                    <a
                                        [routerLink]="['/main', 'catalog', 'categories']"
                                        [class.active]="isURLActive('/main/catalog/categories')"
                                        class="sidemenu-link">
                                        Категорії
                                    </a>
                                </li>
                                <li class="sidemenu-item">
                                    <a
                                        [routerLink]="['/main', 'catalog', 'attributes']"
                                        [class.active]="isURLActive('/main/catalog/attributes')"
                                        class="sidemenu-link">
                                        Атрибути
                                    </a>
                                </li>
                                <li class="sidemenu-item">
                                    <a
                                        [routerLink]="['/main', 'catalog', 'options']"
                                        [class.active]="isURLActive('/main/catalog/options')"
                                        class="sidemenu-link">
                                        Опції
                                    </a>
                                </li>
                                <li class="sidemenu-item">
                                    <a
                                        [routerLink]="['/main', 'catalog', 'units']"
                                        [class.active]="isURLActive('/main/catalog/units')"
                                        class="sidemenu-link">
                                        Одиниці виміру
                                    </a>
                                </li>
<!--                                <li class="sidemenu-item">-->
<!--                                    <a-->
<!--                                        [routerLink]="['/main', 'catalog', 'filters']"-->
<!--                                        [class.active]="isURLActive('/main/catalog/filters')"-->
<!--                                        class="sidemenu-link">-->
<!--                                        Фільтри-->
<!--                                    </a>-->
<!--                                </li>-->
                                <li class="sidemenu-item">
                                    <a
                                        [routerLink]="['/main', 'catalog', 'pages']"
                                        [class.active]="isURLActive('/main/catalog/pages')"
                                        class="sidemenu-link">
                                        Сторiнки
                                    </a>
                                </li>
                            </ul>
                        </mat-expansion-panel>

                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <i class="flaticon-shopping-cart-2"></i>
                                    <span class="title">Продажi</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ul class="sidebar-sub-menu">
                                <li class="sidemenu-item">
                                    <a
                                        [routerLink]="['/main', 'sales', 'orders']"
                                        [class.active]="isURLActive('/main/sales/orders')"
                                        class="sidemenu-link">
                                        Замовлення
                                    </a>
                                </li>
                                <li class="sidemenu-item">
                                    <a
                                        [routerLink]="['/main', 'sales', 'payment-methods']"
                                        [class.active]="isURLActive('/main/sales/payment-methods')"
                                        class="sidemenu-link">
                                        Способи оплати
                                    </a>
                                </li>
                            </ul>
                        </mat-expansion-panel>

                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <i class="flaticon-setting"></i>
                                    <span class="title">Налаштування</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ul class="sidebar-sub-menu">
                                <li class="sidemenu-item">
                                    <a
                                        [routerLink]="['/main', 'settings', 'categories-home']"
                                        [class.active]="isURLActive('/main/settings/categories-home')"
                                        class="sidemenu-link">
                                        Категорії на головнiй
                                    </a>
                                </li>
                                <li class="sidemenu-item">
                                    <a
                                        [routerLink]="['/main', 'settings', 'horizontal-menu']"
                                        [class.active]="isURLActive('/main/settings/horizontal-menu')"
                                        class="sidemenu-link">
                                        Горизонтальне меню
                                    </a>
                                </li>
                                <li class="sidemenu-item">
                                    <a
                                        [routerLink]="['/main', 'settings', 'banners-home']"
                                        [class.active]="isURLActive('/main/settings/banners-home')"
                                        class="sidemenu-link">
                                        Банери на головнiй
                                    </a>
                                </li>
                                <li class="sidemenu-item">
                                    <a
                                        [routerLink]="['/main', 'settings', 'banners-above-categories']"
                                        [class.active]="isURLActive('/main/settings/banners-above-categories')"
                                        class="sidemenu-link">
                                        Банери над категорiями
                                    </a>
                                </li>
                            </ul>
                        </mat-expansion-panel>

                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <i class="flaticon-tools"></i>
                                    <span class="title">Система</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ul class="sidebar-sub-menu">
                                <li class="sidemenu-item">
                                    <a
                                        [routerLink]="['/main', 'system', 'settings']"
                                        [class.active]="isURLActive('/main/system/settings')"
                                        class="sidemenu-link">
                                        Налаштування
                                    </a>
                                </li>
                            </ul>
                        </mat-expansion-panel>

                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <i class="flaticon-to-do-list"></i>
                                    <span class="title">Фiльтри</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ul class="sidebar-sub-menu">
                                <li class="sidemenu-item">
                                    <a
                                        [routerLink]="['/main', 'catalog', 'filters']"
                                        [class.active]="isURLActive('/main/catalog/filters')"
                                        class="sidemenu-link">
                                        Налаштування
                                    </a>
                                </li>
                                <li class="sidemenu-item">
                                    <a
                                        [routerLink]="['/main', 'catalog', 'quick-filters']"
                                        [class.active]="isURLActive('/main/catalog/quick-filters')"
                                        class="sidemenu-link">
                                        Швидкi налаштування
                                    </a>
                                </li>
                            </ul>
                        </mat-expansion-panel>

                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <i class="flaticon-download-1"></i>
                                    <span class="title">Імпорт</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ul class="sidebar-sub-menu">
                                <li class="sidemenu-item">
                                    <a
                                        [routerLink]="['/main', 'import', 'settings']"
                                        [class.active]="isURLActive('/main/import/settings')"
                                        class="sidemenu-link">
                                        Налаштування
                                    </a>
                                </li>
                                <li class="sidemenu-item">
                                    <a
                                        [routerLink]="['/main', 'import', 'products']"
                                        [class.active]="isURLActive('/main/import/products')"
                                        class="sidemenu-link">
                                        Імпорт товарів
                                    </a>
                                </li>
                            </ul>
                        </mat-expansion-panel>
                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <i class="flaticon-growth-1"></i>
                                    <span class="title">Реклама</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ul class="sidebar-sub-menu">
                                <li class="sidemenu-item">
                                    <a
                                        [routerLink]="['/main', 'advertisement', 'categories-home']"
                                        [class.active]="isURLActive('/main/advertisement/categories-home')"
                                        class="sidemenu-link">
                                        Категорії на головній
                                    </a>
                                </li>
                            </ul>
                        </mat-expansion-panel>
                    </ng-container>
                    <ng-container *ngIf="currentPage.startsWith('/market')">
<!--                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true">-->
<!--                            <mat-expansion-panel-header>-->
<!--                                <mat-panel-title>-->
<!--                                    <i class="flaticon-layer"></i>-->
<!--                                    <span class="title">File Manager</span>-->
<!--                                    <span class="badge three">3</span>-->
<!--                                </mat-panel-title>-->
<!--                            </mat-expansion-panel-header>-->
<!--                            <ul class="sidebar-sub-menu">-->
<!--                                <li class="sidemenu-item">-->
<!--                                    <a routerLink="/file-manager" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">-->
<!--                                        My Drive-->
<!--                                    </a>-->
<!--                                </li>-->
<!--                                <li class="sidemenu-item">-->
<!--                                    <a routerLink="/file-manager/assets" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">-->
<!--                                        Assets-->
<!--                                    </a>-->
<!--                                </li>-->
<!--                                <li class="sidemenu-item">-->
<!--                                    <a routerLink="/file-manager/projects" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">-->
<!--                                        Projects-->
<!--                                    </a>-->
<!--                                </li>-->
<!--                                <li class="sidemenu-item">-->
<!--                                    <a routerLink="/file-manager/personal" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">-->
<!--                                        Personal-->
<!--                                    </a>-->
<!--                                </li>-->
<!--                                <li class="sidemenu-item">-->
<!--                                    <a routerLink="/file-manager/templates" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">-->
<!--                                        Templates-->
<!--                                    </a>-->
<!--                                </li>-->
<!--                                <li class="sidemenu-item">-->
<!--                                    <a routerLink="/file-manager/documents" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">-->
<!--                                        Documents-->
<!--                                    </a>-->
<!--                                </li>-->
<!--                                <li class="sidemenu-item">-->
<!--                                    <a routerLink="/file-manager/media" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">-->
<!--                                        Media-->
<!--                                    </a>-->
<!--                                </li>-->
<!--                                <li class="sidemenu-item">-->
<!--                                    <a routerLink="/file-manager/recent-files" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">-->
<!--                                        Recent Files-->
<!--                                    </a>-->
<!--                                </li>-->
<!--                            </ul>-->
<!--                        </mat-expansion-panel>-->
                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <i class="flaticon-menu-1"></i>
                                    <span class="title">Каталог</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ul class="sidebar-sub-menu">
                                <li class="sidemenu-item">
                                    <a
                                        *ngIf="market && market._id"
                                        [routerLink]="['/market', market._id, 'products']"
                                        [class.active]="isURLActive('/market/' + market._id + '/products')"
                                        class="sidemenu-link">
                                        Товари
                                    </a>
                                </li>
                            </ul>
                        </mat-expansion-panel>
                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <i class="flaticon-menu-1"></i>
                                    <span class="title">Модулі</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ul class="sidebar-sub-menu">
                                <li class="sidemenu-item">
                                    <a
                                        *ngIf="market && market._id"
                                        [routerLink]="['/market', market._id, 'google-feed']"
                                        [class.active]="isURLActive('/market/' + market._id + '/xml')"
                                        class="sidemenu-link">
                                        Google Merchant
                                    </a>
                                </li>
                            </ul>
                        </mat-expansion-panel>
                    </ng-container>
                </mat-accordion>
            </div>
        </div>
    </ng-scrollbar>
</div>
