<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Імпорт товарів</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Дашборд
            </a>
        </li>
        <li class="breadcrumb-item gray-color">Імпорт</li>
        <li class="breadcrumb-item fw-semibold">Імпорт товарів</li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card">
    <!-- <mat-card-header>
        <h5 class="mb-0">Список атрибутів</h5>
    </mat-card-header> -->
    <app-local-error></app-local-error>
    <app-local-success></app-local-success>
    <mat-card-content>
        <div class="tabs-without-header">
            <mat-tab-group [selectedIndex]="selectedIndex" disablePagination="true" dynamicHeight>
                <mat-tab>
                    <!-- Start step 1 -->
                    <div class="input-search">
                        <app-filter-input-autocomplete 
                            [label]="'Категорія'" 
                            [dropdownList]="attributesForDropdown" 
                            [key]="'attribute'"
                            (selection)="setCategory($event)">
                        </app-filter-input-autocomplete>
                    </div>
                    <div class="input-search">
                        <app-chips-list-autocomplete
                            [placeholder]="'Опції'"
                            [selectedFilters]="selectedOptions"
                            [filtersList]="optionsList">
                        </app-chips-list-autocomplete>
                    </div>
                    <div style="margin-top: 16px" class="d-flex justify-content-space-between">
                        <button mat-flat-button class="default-btn tagus small" (click)="downloadTemplate()" [disabled]="!categoryID">
                            Завантажити шаблон файлу-імпорту
                        </button>
                        <button mat-flat-button class="default-btn tagus small" (click)="nextStep()">
                            Наступний крок
                        </button>
                    </div>
                    <!-- End step 1 -->
                </mat-tab>
                <mat-tab>
                    <!-- Start step 2 -->
                    <fieldset class="border-input">
                        <legend>Завантаження файлу</legend>
                        <div class="d-flex justify-content-space-between btns-block">
                            <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload>
            
                            <div class="file-upload">
                                <button mat-mini-fab color="primary" class="upload-btn" (click)="fileUpload.click()">
                                    <mat-icon>attach_file</mat-icon>
                                </button>
            
                                {{fileName || "Файл не завантажено"}}
                            </div>
            
                            <div class="d-flex align-items-center">
                                <div class="loader" *ngIf="isLoader">
                                    <mat-spinner class="loader-inner"></mat-spinner>
                                </div>
                                <button mat-flat-button class="default-btn tagus small" (click)="uploadFile()" [disabled]="!fileName || !!disableUploadBtn">
                                    Завантажити товари
                                </button>
                            </div>
                        </div>
                    </fieldset>
                    <div class="d-flex justify-content-space-between align-items-end" *ngIf="isFileAlreadyUpload">
                        <mat-list role="list">
                            <mat-list-item role="listitem"><span class="success-parsing">Парсинг товарів завершено</span></mat-list-item>
                            <mat-list-item role="listitem">Нових товарів: {{ countOfNewProduct }}</mat-list-item>
                            <mat-list-item role="listitem">Знайдено на сайті товарів: {{ countOfExistProduct }}</mat-list-item>
                        </mat-list>
                        <button mat-flat-button class="default-btn tagus small" (click)="nextStep()">
                            Наступний крок
                        </button>
                    </div>
                    <!-- End step 2 -->
                </mat-tab>
                <mat-tab>
                    <!-- Start step 3 -->
                    <div class="mat-elevation-z8 tagus-mat-table">
                        <table mat-table [dataSource]="dataSource">
            
                            <!-- Photo Column -->
                            <ng-container matColumnDef="photo">
                                <th mat-header-cell *matHeaderCellDef class="text-start">Фото</th>
                                <td mat-cell *matCellDef="let element" class="text-start">
                                    <div class="d-flex align-items-center">
                                        <mat-checkbox 
                                            [checked]="!element.is_deleted"
                                            (change)="updateItem(element)">
                                        </mat-checkbox>
                                        <span class="d-flex align-items-center product-info">
                                            <img [src]="backendUrl + '/uploads/' + element.images[0]" alt="">
                                        </span>
                                    </div>
                                </td>
                            </ng-container>

                            <!-- Article Column -->
                            <ng-container matColumnDef="article">
                                <th mat-header-cell *matHeaderCellDef class="text-start">Артикул</th>
                                <td mat-cell *matCellDef="let element" class="text-start">
                                    <div class="d-flex align-items-center">
                                        <span class="d-flex align-items-center product-info">
                                            {{element.article}}
                                        </span>
                                    </div>
                                </td>
                            </ng-container>

                            <!-- Name Column -->
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef class="text-start">Назва</th>
                                <td mat-cell *matCellDef="let element" class="text-start">
                                    <div class="d-flex align-items-center">
                                        <span (click)="openDialog(element)" class="d-flex align-items-center product-info" [title]="element.name_ua">
                                            {{element.name_ua  | truncate:[40, '...']}}
                                        </span>
                                    </div>
                                </td>
                            </ng-container>

                            <!-- Price Column -->
                            <ng-container matColumnDef="price">
                                <th mat-header-cell *matHeaderCellDef class="text-start">Ціна</th>
                                <td mat-cell *matCellDef="let element" class="text-start">
                                    <div class="d-flex align-items-center">
                                        <span class="d-flex align-items-center product-info">
                                            {{element.price}}
                                        </span>
                                    </div>
                                </td>
                            </ng-container>

                            <!-- Price opt Column -->
                            <ng-container matColumnDef="price_opt">
                                <th mat-header-cell *matHeaderCellDef class="text-start">Ціна закупочна</th>
                                <td mat-cell *matCellDef="let element" class="text-start">
                                    <div class="d-flex align-items-center">
                                        <span class="d-flex align-items-center product-info">
                                            {{element.purchase_price}}
                                        </span>
                                    </div>
                                </td>
                            </ng-container>
            
                            <!-- Action Column -->
                            <ng-container matColumnDef="action">
                                <th style="display: flex;justify-content: flex-end" mat-header-cell *matHeaderCellDef></th>
                                <td style="display: flex;justify-content: flex-end" mat-cell *matCellDef="let element">
                                    <span (click)="openDialog(element)" class="cursor-pointer">Детальніше</span>
                                </td>
                            </ng-container>
            
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                        <mat-paginator 
                            [length]="totalRecords"
                            [pageSize]="pageSize"
                            (page)="onPageChange($event)"
                            [pageSizeOptions]="pageSizeOptions"
                            showFirstLastButtons>
                        </mat-paginator>
                        <div class="d-flex justify-content-end align-items-center mt-30">
                            <div class="loader" *ngIf="isLoader">
                                <mat-spinner class="loader-inner"></mat-spinner>
                            </div>
                            <button mat-flat-button class="default-btn tagus small" (click)="startImport()" [disabled]="isDisabledImportBtn">
                                Імпортувати
                            </button>
                        </div>
                        <div class="d-flex align-items-end justify-content-space-between" *ngIf="isSuccessImport">
                            <div>
                                <mat-list role="list">
                                    <mat-list-item role="listitem"><span class="success-parsing">Імпорт товарів завершено</span></mat-list-item>
                                    <mat-list-item role="listitem">Нових товарів: {{ countOfAdded }}</mat-list-item>
                                    <mat-list-item role="listitem">Оновлено товарів: {{ countOfUpdated }}</mat-list-item>
                                </mat-list>
                            </div>
                            <button mat-flat-button class="default-btn tagus small" (click)="newImport()">
                                Новий імпорт
                            </button>
                        </div>
                    </div>
                    <!-- End step 3 -->
                </mat-tab>
            </mat-tab-group>
        </div>
    </mat-card-content>
</mat-card>