export const environment = {
    production: false,
    appName: 'Albatros',
    domain: 'https://dev.albatrosmarket.com.ua',
    backendUrl: 'https://dev.api.albatrosmarket.com.ua',
    apiDashboardUrl: 'https://dev.api.albatrosmarket.com.ua/api/v1/dashboard',
    apiUrlAuth: 'https://dev.api.albatrosmarket.com.ua/api/v1/auth',
    versioning: 'v1',
    user_key: 'auth-user',
};
