import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ErrorService } from 'src/app/components/common/notifications/errors/error.service';
import { EndpointTypeEnum } from 'src/app/enums/endpoint-type.enum';
import { ErrorTypeEnum } from 'src/app/enums/error-type.enum';
import { ApiService } from 'src/app/services/api.service';

interface PeriodicElement {
    name_ua: string;
    parent_id: string;
    _id: string;
    sort: number;
}

interface Filter {
    name_ua: string;
    _id: string;
}

@Component({
    selector: 'app-home-categories',
    templateUrl: './home-categories.component.html',
    styleUrl: './home-categories.component.scss'
})
export class HomeCategoriesComponent implements OnInit, OnDestroy {
    displayedColumns: string[] = ['header', 'name', 'sort', 'action'];
    ELEMENT_DATA: PeriodicElement[] = [];
    dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
    filtersList: Filter[] = [];
    listForDelete: any[] = [];

    totalRecords = 0;
    timeout: any = null;
    pageSizeOptions: any[] = [10];
    pageIndex: number = 0;
    pageSize: number = this.pageSizeOptions[0];

    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(
        private apiService: ApiService,
        private errorService: ErrorService
    ) {
    }

    ngOnInit() {
        this.getCategories();
    }

    ngOnDestroy() {
        this.errorService.clearErrors(ErrorTypeEnum.Global);
        this.errorService.clearErrors(ErrorTypeEnum.Local);
    }

    onPageChange(event: PageEvent): void {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;

        this.getCategories();
    }

    getCategories() {
        this.apiService
            .get(EndpointTypeEnum.Main, '/ads/categories-home', true, null, {
                'page': this.pageIndex
            })
            .subscribe({
                next: (response: any) => {
                    // присваиваем данные переменной ELEMENT_DATA
                    this.ELEMENT_DATA = response.data.categories;
                    // обновляем данные в MatTableDataSource
                    this.dataSource.data = this.ELEMENT_DATA;

                    this.totalRecords = response.data.totalCount;
                },
                error: (err) => {
                    this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
                },
            })
    }

    updateItem(el: any) {
        el.is_deleted = !el.is_deleted;
        if (el.is_deleted) {
            this.listForDelete.push(el._id);
        } else {
            this.listForDelete = this.listForDelete.filter(id => id !== el._id);
        }
    }

    deleteSelected() {
        this.apiService
            .delete(EndpointTypeEnum.Main, '/ads/categories-home', true, '', { ids: this.listForDelete })
            .subscribe({
                next: (response: any) => {
                    this.listForDelete = [];
                    this.pageIndex = 0;
                    this.getCategories();
                    this.paginator.pageIndex = this.pageIndex;
                },
                error: (err) => {
                    this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
                },
            })
    }
}
