<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
  <h5 class="mb-0">Категорії на головнiй</h5>
  <ol class="breadcrumb mb-0 ps-0">
      <li class="breadcrumb-item">
          <a routerLink="/">
              <i class="ri-home-8-line"></i>
              Дашборд
          </a>
      </li>
      <li class="breadcrumb-item gray-color">Реклама</li>
      <li class="breadcrumb-item fw-semibold">Категорії на головній</li>
  </ol>
</div>

<mat-card class="mb-25 tagus-card">
  <mat-card-header>
      <h5 class="mb-0">Редагування</h5>
      <div class="d-flex align-items-center">
          <button mat-raised-button class="tagus small margin-right-10px" form="form" type="submit">Зберегти</button>
          <a class="small" mat-stroked-button [routerLink]="['/main/advertisement/categories-home']" color="warn">Вийти</a>
      </div>
  </mat-card-header>
  <mat-card-content>
      <app-local-success></app-local-success>
      <app-local-error></app-local-error>
      <form [formGroup]="form" (submit)="onFormSubmit(form)" id="form">
          <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" dynamicHeight>
              <!-- Загальне -->
              <mat-tab label="Загальне"><br>
                  <div class="row" style="margin: 0">
                      <div class="col-lg-4 col-md-4 col-sm-4">
                          <mat-form-field class="width-100">
                              <mat-label>Заголовок (укр)</mat-label>
                              <input matInput formControlName="header_ua">
                              <mat-error *ngIf="(formWasSubmitted && form.controls['header_ua'].invalid) || (form.controls['header_ua'].invalid && form.controls['header_ua'].touched)">
                                  <ng-container *ngIf="form.controls['header_ua'].errors?.['required']">
                                      Обов'язкове поле
                                  </ng-container>
                                  <ng-container *ngIf="form.controls['header_ua'].errors?.['minlength']">
                                      Поле може містити мінімум 2 символи
                                  </ng-container>
                                  <ng-container *ngIf="form.controls['header_ua'].errors?.['maxlength']">
                                      Поле може містити максимум 255 символів
                                  </ng-container>
                              </mat-error>
                          </mat-form-field>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-4">
                          <mat-form-field class="width-100">
                              <mat-label>Заголовок (рус)</mat-label>
                              <input matInput formControlName="header_ru">
                              <mat-error *ngIf="(formWasSubmitted && form.controls['header_ru'].invalid) || (form.controls['header_ru'].invalid && form.controls['header_ru'].touched)">
                                  <ng-container *ngIf="form.controls['header_ru'].errors?.['required']">
                                      Обов'язкове поле
                                  </ng-container>
                                  <ng-container *ngIf="form.controls['header_ru'].errors?.['minlength']">
                                      Поле може містити мінімум 2 символи
                                  </ng-container>
                                  <ng-container *ngIf="form.controls['header_ru'].errors?.['maxlength']">
                                      Поле може містити максимум 255 символів
                                  </ng-container>
                              </mat-error>
                          </mat-form-field>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-4">
                        <mat-form-field class="width-100">
                            <mat-label>Сортування</mat-label>
                            <input matInput formControlName="sort">
                            <mat-error *ngIf="(formWasSubmitted && form.controls['sort'].invalid) || (form.controls['sort'].invalid && form.controls['sort'].touched)">
                                <ng-container *ngIf="form.controls['sort'].errors?.['required']">
                                    Обов'язкове поле
                                </ng-container>
                            </mat-error>
                        </mat-form-field>
                    </div>
                  </div>
                  <div class="row" style="margin: 0">
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <mat-form-field class="width-100">
                            <mat-label>Посилання (укр) - починаючи з http/https</mat-label>
                            <input matInput formControlName="link_show_all_ua">
                            <mat-error *ngIf="(formWasSubmitted && form.controls['link_show_all_ua'].invalid) || (form.controls['link_show_all_ua'].invalid && form.controls['link_show_all_ua'].touched)">
                                <ng-container *ngIf="form.controls['link_show_all_ua'].errors?.['required']">
                                    Обов'язкове поле
                                </ng-container>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <mat-form-field class="width-100">
                            <mat-label>Посилання (рус) - починаючи з http/https</mat-label>
                            <input matInput formControlName="link_show_all_ru">
                            <mat-error *ngIf="(formWasSubmitted && form.controls['link_show_all_ru'].invalid) || (form.controls['link_show_all_ru'].invalid && form.controls['link_show_all_ru'].touched)">
                                <ng-container *ngIf="form.controls['link_show_all_ru'].errors?.['required']">
                                    Обов'язкове поле
                                </ng-container>
                            </mat-error>
                        </mat-form-field>
                    </div>
                  </div>
                  <div class="row" style="margin: 0">
                      <div class="col-lg-12">
                          <mat-form-field class="width-100">
                              <mat-label>Вибір товарів</mat-label>
                              <input #input
                                  type="text"
                                  placeholder=""
                                  matInput
                                 
                                  [matAutocomplete]="auto"
                                  (debounceInput)="getOption($event)" 
                                  [debounceTime]="300">
                              <mat-autocomplete requireSelection #auto="matAutocomplete" (optionSelected)="onUpdateOptions($event)">
                                  <mat-option *ngFor="let option of filteredOptions" [value]="option">
                                      {{ option.product.name_ua }} ({{ option.product.article }})
                                  </mat-option>
                              </mat-autocomplete>
                              <mat-hint>Почніть вводити назву або артикул</mat-hint>
                          </mat-form-field>
  
                          <ng-container *ngFor="let selectedOption of selectedOptions; let i = index">
                              <div style="cursor: pointer" (click)="removeOption(i)">
                                  ❌ {{ selectedOption.product.name_ua }}
                              </div>
                          </ng-container>
                      </div>
                  </div>
              </mat-tab>
              <!-- Банера ПК" -->
              <mat-tab label="Банера ПК"><br>
                  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
                      <!-- УКР -->
                      <mat-tab label="УКР"><br>
                          <div *ngFor="let bannerItem of addedBannerListUA; let i = index">
                              <div class="row" style="margin: 0">
                                  <div class="col-lg-1 col-md-1 col-sm-12">
                                      <div class="width-100">
                                          <input type="file" (change)="uploadImage(i, $event, 'image_pc_ua')" class="img-upload" [id]="'img_ua_' + i" accept="image/*" >
                                          <label [for]="'img_ua_' + i">
                                              <div class="btn-upload" *ngIf="addedBannerListUA[i].image === ''">
                                                  <mat-icon>upload</mat-icon>
                                              </div>
                                              <div *ngIf="addedBannerListUA[i].image" class="img-uploaded" [ngStyle]="{'background-image': 'url(' + backendUrl + '/uploads/' + addedBannerListUA[i].image + ')' }"></div>
                                          </label>
                                      </div>
                                  </div>
                                  <div class="col-lg-5 col-md-5 col-sm-12">
                                      <mat-form-field class="width-100">
                                          <mat-label>Посилання (укр) - починаючи з http/https</mat-label>
                                          <input matInput (change)="onUpdateBanner(i, 'link', $event, 'ua')" [value]="addedBannerListUA[i].link || null">
                                      </mat-form-field>
                                  </div>
                                  <div class="col-lg-5 col-md-5 col-sm-12">
                                      <mat-form-field class="width-100">
                                          <mat-label>Позиція</mat-label>
                                          <input matInput (change)="onUpdateBanner(i, 'position', $event, 'ua')" [value]="addedBannerListUA[i].position || null">
                                      </mat-form-field>
                                  </div>
                                  <div class="col-lg-1 col-md-1 col-sm-12">
                                      <button mat-stroked-button color="warn" class="btn-delete" (click)="removeBanner(i, 'ua')">
                                          <mat-icon>delete</mat-icon>
                                      </button>
                                  </div>
                              </div>
                              <hr/>
                          </div>
                          <div class="row" style="margin: 0;">
                              <div class="col-lg-4 col-md-4 col-sm-4">
                                  <button type="button" mat-raised-button class="tagus" (click)="addBanner('ua')">Додати</button>
                              </div>
                          </div>
                      </mat-tab>
                      <!-- РУС -->
                      <mat-tab label="РУС"><br>
                          <div *ngFor="let bannerItem of addedBannerListRU; let i = index">
                              <div class="row" style="margin: 0">
                                  <div class="col-lg-1 col-md-1 col-sm-12">
                                      <div class="width-100">
                                          <input type="file" (change)="uploadImage(i, $event, 'image_pc_ru')" class="img-upload" [id]="'img_ru_' + i" accept="image/*" >
                                          <label [for]="'img_ru_' + i">
                                              <div class="btn-upload" *ngIf="addedBannerListRU[i].image === ''">
                                                  <mat-icon>upload</mat-icon>
                                              </div>
                                              <div *ngIf="addedBannerListRU[i].image" class="img-uploaded" [ngStyle]="{'background-image': 'url(' + backendUrl + '/uploads/' + addedBannerListRU[i].image + ')' }"></div>
                                          </label>
                                      </div>
                                  </div>
                                  <div class="col-lg-5 col-md-5 col-sm-12">
                                      <mat-form-field class="width-100">
                                          <mat-label>Посилання (рус) - починаючи з http/https</mat-label>
                                          <input matInput (change)="onUpdateBanner(i, 'link', $event, 'ru')" [value]="addedBannerListRU[i].link || null">
                                      </mat-form-field>
                                  </div>
                                  <div class="col-lg-5 col-md-5 col-sm-12">
                                      <mat-form-field class="width-100">
                                          <mat-label>Позиція</mat-label>
                                          <input matInput (change)="onUpdateBanner(i, 'position', $event, 'ru')" [value]="addedBannerListRU[i].position || null">
                                      </mat-form-field>
                                  </div>
                                  <div class="col-lg-1 col-md-1 col-sm-12">
                                      <button mat-stroked-button color="warn" class="btn-delete" (click)="removeBanner(i, 'ru')">
                                          <mat-icon>delete</mat-icon>
                                      </button>
                                  </div>
                              </div>
                              <hr/>
                          </div>
                          <div class="row" style="margin: 0;">
                              <div class="col-lg-4 col-md-4 col-sm-4">
                                  <button type="button" mat-raised-button class="tagus" (click)="addBanner('ru')">Додати</button>
                              </div>
                          </div>
                      </mat-tab>
                  </mat-tab-group>
              </mat-tab>
              <!-- Банера МОБ -->
              <mat-tab label="Банера МОБ"><br>
                  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
                      <!-- УКР -->
                      <mat-tab label="УКР"><br>
                          <div class="row" style="margin: 0">
                              <div class="col-lg-1 col-md-1 col-sm-12">
                                  <div class="width-100">
                                      <input type="file" (change)="uploadImage(0, $event, 'image_mob_ua')" class="img-upload" [id]="'img_mob_ua_' + 0" accept="image/*" >
                                      <label [for]="'img_mob_ua_' + 0">
                                          <div class="btn-upload" *ngIf="addedBannerListMob[0].image_ua === ''">
                                              <mat-icon>upload</mat-icon>
                                          </div>
                                          <div *ngIf="addedBannerListMob[0].image_ua" class="img-uploaded" [ngStyle]="{'background-image': 'url(' + backendUrl + '/uploads/' + addedBannerListMob[0].image_ua + ')' }"></div>
                                      </label>
                                  </div>
                              </div>
                              <div class="col-lg-11 col-md-11 col-sm-12">
                                  <mat-form-field class="width-100">
                                      <mat-label>Посилання - починаючи з http/https</mat-label>
                                      <input matInput (change)="onUpdateBanner(0, 'link_ua', $event)" [value]="addedBannerListMob[0].link_ua || null">
                                  </mat-form-field>
                              </div>
                          </div>
                      </mat-tab>
                      <!-- РУС -->
                      <mat-tab label="РУС"><br>
                          <div class="row" style="margin: 0">
                              <div class="col-lg-1 col-md-1 col-sm-12">
                                  <div class="width-100">
                                      <input type="file" (change)="uploadImage(0, $event, 'image_mob_ru')" class="img-upload" [id]="'img_mob_ru_' + 0" accept="image/*" >
                                      <label [for]="'img_mob_ru_' + 0">
                                          <div class="btn-upload" *ngIf="addedBannerListMob[0].image_ru === ''">
                                              <mat-icon>upload</mat-icon>
                                          </div>
                                          <div *ngIf="addedBannerListMob[0].image_ru" class="img-uploaded" [ngStyle]="{'background-image': 'url(' + backendUrl + '/uploads/' + addedBannerListMob[0].image_ru + ')' }"></div>
                                      </label>
                                  </div>
                              </div>
                              <div class="col-lg-11 col-md-11 col-sm-12">
                                  <mat-form-field class="width-100">
                                      <mat-label>Посилання - починаючи з http/https</mat-label>
                                      <input matInput (change)="onUpdateBanner(0, 'link_ru', $event)" [value]="addedBannerListMob[0].link_ru || null">
                                  </mat-form-field>
                              </div>
                          </div>
                      </mat-tab>
                  </mat-tab-group>
              </mat-tab>
          </mat-tab-group>
      </form>
      <!-- <form [formGroup]="form" (submit)="onFormSubmit(form)" id="form">
        <div *ngFor="let addedCategory of addedCategories; let i = index">
            <div class="row" style="margin: 0">
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <mat-form-field appearance="fill" class="width-100">
                        <mat-label>Категорiя</mat-label>
                        <mat-select [(value)]="addedCategories[i].category" (selectionChange)="onUpdateCategory(i, 'category', $event)">
                            <mat-option *ngFor="let category of categoriesForDropdown" [value]="category._id">
                                {{category.name_ua}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <mat-form-field class="width-100">
                        <mat-label>Заголовок (укр)</mat-label>
                        <input matInput (change)="onUpdateCategory(i, 'header_ua', $event)" [value]="addedCategories[i].header_ua || null">
                    </mat-form-field>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <mat-form-field class="width-100">
                        <mat-label>Заголовок (рус)</mat-label>
                        <input matInput (change)="onUpdateCategory(i, 'header_ru', $event)" [value]="addedCategories[i].header_ru || null">
                    </mat-form-field>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="width-100">
                        <label class="d-block mb-12 fw-semibold gray-color">Зображення (укр) ПК</label>
                        <input type="file" accept="image/*" (change)="uploadImage(i, $event, 'image_pc_ua')">
                        <br/>
                        <img *ngIf="addedCategories[i].image_pc_ua" [src]="backendUrl + '/uploads/' + addedCategories[i].image_pc_ua" class="border-radius mt-15" style="height:150px">
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="width-100">
                        <label class="d-block mb-12 fw-semibold gray-color">Зображення (укр) Моб</label>
                        <input type="file" accept="image/*" (change)="uploadImage(i, $event, 'image_mob_ua')">
                        <br/>
                        <img *ngIf="addedCategories[i].image_mob_ua" [src]="backendUrl + '/uploads/' + addedCategories[i].image_mob_ua" class="border-radius mt-15" style="height:150px">
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <mat-form-field class="width-100">
                        <mat-label>Посилання (укр) - починаючи з http/https</mat-label>
                        <input matInput (change)="onUpdateCategory(i, 'link_ua', $event)" [value]="addedCategories[i].link_ua || null">
                    </mat-form-field>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="width-100">
                        <label class="d-block mb-12 fw-semibold gray-color">Зображення (рус) ПК</label>
                        <input type="file" accept="image/*" (change)="uploadImage(i, $event, 'image_pc_ru')">
                        <br/>
                        <img *ngIf="addedCategories[i].image_pc_ru" [src]="backendUrl + '/uploads/' + addedCategories[i].image_pc_ru" class="border-radius mt-15" style="height:150px">
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="width-100">
                        <label class="d-block mb-12 fw-semibold gray-color">Зображення (рус) Моб</label>
                        <input type="file" accept="image/*" (change)="uploadImage(i, $event, 'image_mob_ru')">
                        <br/>
                        <img *ngIf="addedCategories[i].image_mob_ru" [src]="backendUrl + '/uploads/' + addedCategories[i].image_mob_ru" class="border-radius mt-15" style="height:150px">
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <mat-form-field class="width-100">
                        <mat-label>Посилання (рус) - починаючи з http/https</mat-label>
                        <input matInput (change)="onUpdateCategory(i, 'link_ru', $event)" [value]="addedCategories[i].link_ru || null">
                    </mat-form-field>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <mat-form-field class="width-100">
                        <mat-label>Кількість</mat-label>
                        <input matInput type="number" (change)="onUpdateCategory(i, 'count', $event)" [value]="addedCategories[i].count || null">
                    </mat-form-field>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <mat-form-field class="width-100">
                        <mat-label>Позицiя</mat-label>
                        <input matInput type="number" (change)="onUpdateCategory(i, 'position', $event)" [value]="addedCategories[i].position || null">
                    </mat-form-field>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <button mat-stroked-button color="warn" (click)="removeCategory(i)">Видалити категорiю</button>
                </div>
            </div>
            <hr/>
        </div>

        <div class="row" style="margin: 0;">
            <div class="col-lg-4 col-md-4 col-sm-4">
                <button type="button" mat-raised-button class="tagus" (click)="addCategory()">Додати</button>
            </div>
        </div>
    </form> -->
  </mat-card-content>
</mat-card>